<template>
  <input-wrapper
    ref="inputWrapperRef"
    v-bind="{ ...$props, ...$attrs }"
    v-model="model"
    @enter="$emit('enter')"
    @input="$emit('input', $event)"
    @focus="$emit('focus', $event)"
    @click="focus"
  >
    <template #left>
      <l-icon
        v-if="icon && iconPosition == 'left'"
        :name="icon"
        class="text-n-800 dark:text-n-200"
        :class="[size === 'x-small' ? 'ml-2' : 'ml-4']"
      />
    </template>
    <template #right>
      <l-icon
        v-if="icon && iconPosition == 'right'"
        :name="icon"
        class="text-n-800 dark:text-n-200"
        :class="[size === 'x-small' ? 'mr-2' : 'mr-4']"
      />
    </template>
  </input-wrapper>
</template>

<script setup lang="ts">
import {
  computed,
  defineModel,
  useTemplateRef,
  type InputHTMLAttributes
} from 'vue'

import type { LIconName } from '../icon.type'
import LIcon from '../LIcon.vue'
import { type InputProps } from './inputTypes'
import InputWrapper from './InputWrapper.vue'

type Props = InputProps & {
  icon?: LIconName
  iconPosition?: 'left' | 'right'
} & /* @vue-ignore */ InputHTMLAttributes

const DEFAULT_MAX_NUMBER = 999_999_999

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  iconPosition: 'right',
  maxLength: 250
})

defineEmits<{
  enter: []
  input: [Event]
  focus: [FocusEvent]
}>()

const inputWrapperRef = useTemplateRef('inputWrapperRef')

const [model, modifiers] = defineModel<
  string | number | null,
  'number' | 'currency' | 'integer'
>({
  default: null,
  get: (oldValue): string | number | null => {
    if (oldValue === null) return null
    if (!modifiers.number && !modifiers.currency) return oldValue
    const parsedValue = oldValue
    return modifiers.currency ? +parsedValue / 100 : parsedValue
  },
  set: value => {
    if (value === null) return null
    if (!modifiers.number && !modifiers.currency && !modifiers.integer) {
      return value
    }

    const parsedValue = value.toString().replace(',', '.')
    let numberValue = parseFloat(parsedValue)

    if (isNaN(numberValue)) return null

    const max = props.max ?? DEFAULT_MAX_NUMBER
    const min = props.min ?? -DEFAULT_MAX_NUMBER

    if (numberValue > max) {
      numberValue = max
      updateVisualValue(numberValue)
    }
    if (numberValue < min) {
      numberValue = min
      updateVisualValue(numberValue)
    }

    // Round to 2 decimal places
    const roundedNumber = Math.round(numberValue * 100) / 100
    if (roundedNumber !== numberValue) {
      numberValue = roundedNumber
      updateVisualValue(numberValue)
    }

    if (modifiers.integer) {
      numberValue = Math.round(numberValue)
    }

    if (modifiers.currency) {
      numberValue = Math.round(numberValue * 100)
    }

    return numberValue
  }
})

function updateVisualValue(number: number): void {
  // Prevent showing a number different from the model
  inputWrapperRef.value!.inputRef!.value = number.toString()
}

function focus(): void {
  inputWrapperRef.value?.focusInput()
  setTimeout(() => {
    const target = inputWrapperRef.value
    target?.inputRef?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }, 200)
}

const inputRef = computed(() => inputWrapperRef.value!.inputRef!)

defineExpose({ focus, inputRef })
</script>
