<template>
  <div class="pb-6">
    <h2 class="text-n-0 text-[1.75rem] font-heading font-bold title">
      {{ $t('login.recover-title') }}
    </h2>
    <div class="text-n-0 w-full text-left text-base">
      {{ $t('login.recover-description') }}
    </div>
  </div>
  <div>
    <l-field :label="$t('inputs.password-label')">
      <l-password-input
        v-model="password"
        :placeholder="$t('inputs.password-text-placeholder')"
        :wrong-value="errors.password.active"
        :error-message="errors.password.message"
        @input="handleInput"
      />
    </l-field>
    <l-field :label="$t('inputs.password-repeat-placeholder')">
      <l-password-input
        v-model="repeatPassword"
        :placeholder="$t('inputs.password-repeat-placeholder')"
        :wrong-value="errors.repeat.active"
        :error-message="errors.repeat.message"
        @input="handleInput"
      />
    </l-field>
    <l-button class="w-full" size="small" @click="confirmPassword">
      {{ $t('ctas.confirm-password') }}
    </l-button>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { LButton, LField, LPasswordInput } from '@last/core-ui/paprika'

import { useAuthStore } from '@/store/auth'

type Props = {
  changePasswordToken?: string
}

const props = defineProps<Props>()

const authStore = useAuthStore()
const { t } = useI18n()

const password = ref<string>()
const repeatPassword = ref<string>()
const useErrors = ref<boolean>(false)

const emit = defineEmits(['success', 'error'])

type ErrorDescriptor = {
  message: string
  active: boolean
}
const errors = ref<{ password: ErrorDescriptor; repeat: ErrorDescriptor }>({
  password: { message: '', active: false },
  repeat: { message: '', active: false }
})

onMounted(() => {
  if (!props.changePasswordToken) {
    emit('error')
  }
})

function handleInput(): void {
  useErrors.value = false
  errors.value.password.active = false
  errors.value.repeat.active = false
  if (!password.value || !repeatPassword.value || !useErrors.value) {
    return
  }
  validatePasswords()
}

async function confirmPassword(): Promise<void> {
  useErrors.value = true
  if (validatePasswords()) {
    const result = await authStore.resetPassword(
      props.changePasswordToken!,
      password.value!
    )

    if (result) {
      emit('success')
    } else {
      emit('error')
    }
  }
}

function validatePasswords(): boolean {
  const regexp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z])(?!.* ).{8,}$/gm
  if (!regexp.test(password.value || '')) {
    errors.value.password.active = true
    errors.value.password.message = t('login.forgot-password-error')
  }
  if (password.value !== repeatPassword.value) {
    errors.value.repeat.active = true
    errors.value.repeat.message = t('login.forgot-repeat-error')
  }

  return errors.value.password.active || errors.value.repeat.active
    ? false
    : true
}
</script>
