import plugin from 'tailwindcss/plugin.js'

import { default as coreUiTheme } from '../core-ui/tailwind.config.js'

export default {
  mode: 'jit',
  darkMode: 'selector',
  theme: {
    colors: {
      ...coreUiTheme.theme.colors,
      blur: 'rgba(24, 24, 37, 0.8)',
      list: {
        1: '#39C0FE',
        2: coreUiTheme.theme.colors.g[500],
        3: '#1AD194',
        4: '#1AD126',
        5: coreUiTheme.theme.colors.y[400],
        6: '#FC9105',
        7: '#FF5708',
        8: coreUiTheme.theme.colors.r[500],
        9: '#D11A88',
        10: '#A01AD1',
        11: coreUiTheme.theme.colors.v[400],
        12: '#1A4BD1',
        13: coreUiTheme.theme.colors.b[500]
      }
    },
    extend: {
      fontFamily: {
        heading: ['Poppins'],
        title: ['Poppins'],
        text: ['Poppins'],
        body: ['Nunito Sans']
      },
      backgroundImage: {
        'corner-gradient':
          'radial-gradient(at top right, #43396a 0%, #2a2a42 40%)',
        'dual-b-y': `linear-gradient(to bottom,${coreUiTheme.theme.colors.b['500']} 0%,${coreUiTheme.theme.colors.b['500']} 50%,${coreUiTheme.theme.colors.y['500']} 50%,${coreUiTheme.theme.colors.y['500']} 100%)`,
        'dual-r-y': `linear-gradient(to bottom,${coreUiTheme.theme.colors.r['300']} 0%,${coreUiTheme.theme.colors.r['300']} 50%,${coreUiTheme.theme.colors.y['500']} 50%,${coreUiTheme.theme.colors.y['500']} 100%)`
      }
    }
  },
  variants: {
    borderColor: ['responsive', 'hover', 'focus', 'focus-within'],
    backgroundColor: ['responsive', 'hover', 'focus', 'active'],
    margin: ['responsive', 'first']
  },
  plugins: [
    plugin(function ({ addVariant }) {
      addVariant('not-last', '&:not(:last-child)')
    })
  ],
  content: [`src/**/*.vue`, `../core-ui/paprika/**/*.{vue,ts}`],
  safelist: [{ pattern: /bg-list-(1|2|3|4|5|6|7|8|9|10|11|12|13)/ }]
}
