<template>
  <div>
    <l-option-selector
      v-if="options.length < 4"
      v-model="paymentMethodSelector"
      :options="options"
      @update:model-value="$emit('change', paymentMethodSelector)"
    />
    <l-select
      v-else
      v-model="paymentMethodSelector"
      :options="options"
      :search="false"
      @update:model-value="$emit('change', paymentMethodSelector)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'

import { LOptionSelector, LSelect } from '@last/core-ui/paprika'

import { PaymentMethod } from '@/types'

type Props = {
  paymentMethods: PaymentMethod[]
  selectedMethod?: string
}

const props = defineProps<Props>()

const emit = defineEmits<{
  change: [string]
}>()

const paymentMethodSelector = ref<string>(
  props.selectedMethod ?? props.paymentMethods[0].type
)

onMounted(() => {
  if (!props.selectedMethod) {
    paymentMethodSelector.value = props.paymentMethods[0].type
    emit('change', paymentMethodSelector.value)
  }
})

const options = computed(() => {
  if (!props.paymentMethods) return []
  return props.paymentMethods.map(p => {
    return {
      label: p.name,
      value: p.type
    }
  })
})
</script>
