/* tslint:disable */
/* eslint-disable */
/**
 * POS API
 * Internal POS API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCustomer200ResponseTabsInnerProductsInner
 */
export interface GetCustomer200ResponseTabsInnerProductsInner {
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseTabsInnerProductsInner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseTabsInnerProductsInner
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof GetCustomer200ResponseTabsInnerProductsInner
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof GetCustomer200ResponseTabsInnerProductsInner
     */
    finalPrice: number;
}

/**
 * Check if a given object implements the GetCustomer200ResponseTabsInnerProductsInner interface.
 */
export function instanceOfGetCustomer200ResponseTabsInnerProductsInner(value: object): value is GetCustomer200ResponseTabsInnerProductsInner {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('quantity' in value) || value['quantity'] === undefined) return false;
    if (!('finalPrice' in value) || value['finalPrice'] === undefined) return false;
    return true;
}

export function GetCustomer200ResponseTabsInnerProductsInnerFromJSON(json: any): GetCustomer200ResponseTabsInnerProductsInner {
    return GetCustomer200ResponseTabsInnerProductsInnerFromJSONTyped(json, false);
}

export function GetCustomer200ResponseTabsInnerProductsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCustomer200ResponseTabsInnerProductsInner {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'quantity': json['quantity'],
        'finalPrice': json['finalPrice'],
    };
}

export function GetCustomer200ResponseTabsInnerProductsInnerToJSON(json: any): GetCustomer200ResponseTabsInnerProductsInner {
    return GetCustomer200ResponseTabsInnerProductsInnerToJSONTyped(json, false);
}

export function GetCustomer200ResponseTabsInnerProductsInnerToJSONTyped(value?: GetCustomer200ResponseTabsInnerProductsInner | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'quantity': value['quantity'],
        'finalPrice': value['finalPrice'],
    };
}

