import { Capacitor } from '@capacitor/core'
import { Ref, ref } from 'vue'

import Cashdro from '@/integrations/cashmachine/cashdro.js'
import CashGuard from '@/integrations/cashmachine/cashguard.js'
import CashKeeper from '@/integrations/cashmachine/cashkeeper.js'
import Cashlogy from '@/integrations/cashmachine/cashlogy'
import DevCashMachine from '@/integrations/cashmachine/devcashmachine.js'
import Glory from '@/integrations/cashmachine/glory.js'
import { cashMachineStatus } from '@/plugins/cashMachineStatus'
import { Config } from '@/types/config'

class CashMachine {
  public inputAmount: Ref<number>
  public totalAmount: Ref<number>
  private hasStatus: boolean = false
  private machine: any
  public method: string | null = null
  public methods = ['cashdro', 'cashlogy', 'cashguard', 'glory', 'cashkeeper']

  constructor() {
    this.inputAmount = ref(0)
    this.totalAmount = ref(0)
  }

  async init(config: Config, preferredConfigId: string) {
    const platform = Capacitor.getPlatform()

    const inputAmountListener = this.inputAmountListener.bind(this)
    const totalAmountListener = this.totalAmountListener.bind(this)

    const cashMachines = {
      Cashdro,
      Cashlogy,
      CashGuard,
      Glory,
      CashKeeper
    } as const

    for (const method of config.paymentMethodsConfig) {
      if (!method.configs) continue
      for (const methodConfig of method.configs) {
        if (
          methodConfig.id === preferredConfigId ||
          (!preferredConfigId && (methodConfig.ip || methodConfig.port))
        ) {
          if (!import.meta.env.PROD && platform === 'web') {
            DevCashMachine.init(inputAmountListener, totalAmountListener)
            this.machine = DevCashMachine
            this.hasStatus = true
          } else {
            await cashMachines[method.name].init({
              ip: methodConfig.ip,
              port: methodConfig.port,
              name: methodConfig.name,
              password: methodConfig.password,
              username: methodConfig.username,
              type: methodConfig.type,
              inputAmountListener,
              totalAmountListener
            })
            this.machine = cashMachines[method.name]
            this.hasStatus = true
          }
          this.method = method.name.toLowerCase()
          return
        }
      }
    }
  }

  private inputAmountListener(amount: number) {
    this.inputAmount.value = amount
  }

  private totalAmountListener(amount: number) {
    this.totalAmount.value = amount
  }

  private onCancel() {
    return this.machine.cancel()
  }

  async charge(amount: number): Promise<number> {
    let component = null
    if (this.hasStatus) {
      component = cashMachineStatus({
        inputAmount: this.inputAmount,
        amount,
        onCancel: this.onCancel.bind(this)
      })
    }
    try {
      return await this.machine.charge(amount)
    } finally {
      component?.close()
    }
  }

  async payIn(amount: number): Promise<number> {
    let component = null
    if (this.hasStatus) {
      component = cashMachineStatus({
        inputAmount: this.inputAmount,
        amount,
        onCancel: this.onCancel.bind(this)
      })
    }
    const res = await this.machine.payIn(amount)
    component?.close()
    return res
  }

  async payOut(amount: number): Promise<number> {
    return this.machine.payOut(amount)
  }

  async close(): Promise<void> {
    if (!this.machine.close) return
    return this.machine.close()
  }

  async refreshTotalAmount(): Promise<number> {
    if (!this.machine.refreshTotalAmount) return 0
    return this.machine.refreshTotalAmount()
  }

  isEnabled(): boolean {
    return !!this.machine
  }

  async openBackoffice(): Promise<void> {
    if (!this.machine.openBackoffice) return
    await this.machine.openBackoffice()
  }
}

export default new CashMachine()
