<template>
  <div class="w-full">
    <div class="flex w-full pb-4 items-center space-x-2">
      <div
        id="prev-month"
        class="w-7 h-7 flex justify-center items-center cursor-pointer rounded-full hover:bg-n-50 dark:hover:bg-n-600"
        :class="{ 'pointer-events-none opacity-50': cantGoBack }"
        @click="previousMonth()"
      >
        <l-icon name="bracket-left" class="text-n-600 dark:text-n-0" small />
      </div>
      <div v-if="showSelectors" class="flex flex-1 space-x-2">
        <l-select v-model="month" :options="months" class="flex-1 capitalize" />
        <l-select v-model="year" :options="years" class="flex-1" />
      </div>
      <div
        v-else
        class="flex-1 font-body text-n-800 dark:text-n-0 text-center capitalize"
      >
        {{ month }} {{ year }}
      </div>
      <div
        id="next-month"
        class="w-7 h-7 flex justify-center items-center rounded-full cursor-pointer hover:bg-n-50 dark:hover:bg-n-600"
        @click="nextMonth()"
      >
        <l-icon name="bracket-right" class="text-n-600 dark:text-n-0" small />
      </div>
    </div>
    <div class="flex w-full text-n-400 text-xs pb-3">
      <div
        v-for="day in weekdays"
        :key="day"
        class="flex-1 text-center uppercase"
      >
        {{ day }}
      </div>
    </div>
    <div class="w-full pb-2">
      <div v-for="(row, index) in days" :key="index" class="flex-1 flex">
        <div
          v-for="day in row"
          :id="'day-' + day.day"
          :key="day.date.getTime()"
          class="flex-1 text-n-800 dark:text-n-0 text-sm flex justify-center py-0.5"
          :class="{ 'opacity-30': !day.enabled, 'cursor-pointer': day.enabled }"
          @click="select(day)"
        >
          <div
            class="w-8 h-8 rounded-full leading-8 text-center"
            :class="{
              'hover:bg-n-50 dark:hover:bg-n-600': day.enabled && !day.selected,
              'bg-v-300 text-n-0': day.selected
            }"
          >
            {{ day.day }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  addDays,
  addMonths,
  eachDayOfInterval,
  endOfISOWeek,
  format,
  getDate,
  getISODay,
  isBefore,
  isSameDay,
  isSameMonth,
  set,
  setDay,
  setMonth,
  setYear,
  startOfISOWeek,
  startOfMonth,
  subDays,
  subMonths
} from 'date-fns'
import { computed, defineModel, onMounted, ref, watch } from 'vue'

import { LIcon, LSelect } from '@last/core-ui/paprika'

type Props = {
  /** Dates that can't be selected */
  unavailableDates?: Date[]
  /** Show month and year as selects */
  showSelectors?: boolean
  /** Maximum date that can be selected */
  maxDate?: Date | null
  /** Show only future dates inclusive today */
  onlyFuture?: boolean
  /** Show only working days */
  onlyWorkingDays?: boolean
  /**
   * Default time of the date
   * If not provided, the current time will be used
   * Format HH:mm
   */
  defaultTime?: string
}

type Day = { date: Date; day: number; enabled: boolean; selected: boolean }

const props = withDefaults(defineProps<Props>(), {
  unavailableDates: () => [],
  showSelectors: false,
  maxDate: null,
  onlyFuture: false,
  onlyWorkingDays: false,
  defaultTime: undefined
})

const model = defineModel<Date | null>({
  default: null
})

const currentDate = ref(new Date())
const today = new Date()

onMounted(() => {
  if (model.value) {
    currentDate.value = model.value
  }
})

const years = computed(() => {
  let years = []
  const pastYears = [
    ...Array(3)
      .fill(0)
      .map((_, i) => new Date().getFullYear() - i - 1)
  ]
  const futureYears = [
    new Date().getFullYear(),
    ...Array(3)
      .fill(0)
      .map((_, i) => new Date().getFullYear() + i + 1)
  ]
  if (props.onlyFuture) {
    years = futureYears
  } else {
    years = [...pastYears, ...futureYears]
  }
  return years.sort().map(y => {
    return { label: y.toString(), value: y.toString() }
  })
})

const cantGoBack = computed(() => {
  return (
    props.onlyFuture &&
    today.getMonth() === currentDate.value.getMonth() &&
    today.getFullYear() === currentDate.value.getFullYear()
  )
})

const weekdays = computed(() => {
  return eachDayOfInterval({
    start: startOfISOWeek(today),
    end: endOfISOWeek(today)
  }).map(day => format(day, 'ccc'))
})

const days = computed(() => {
  let firstWeek = startOfISOWeek(startOfMonth(currentDate.value))
  if (getISODay(firstWeek) !== 1) {
    firstWeek = startOfISOWeek(addDays(firstWeek, -7))
  }

  const endDate = addDays(firstWeek, 6 * 7 - 1)

  return chunks(
    eachDayOfInterval({ start: firstWeek, end: endDate }).map(date => {
      const maxDateFilter = props.maxDate
        ? isBefore(date, new Date(props.maxDate))
        : true

      return {
        date: props.defaultTime
          ? set(date, {
              hours: parseInt(props.defaultTime.split(':')[0]),
              minutes: parseInt(props.defaultTime.split(':')[1]),
              seconds: 0,
              milliseconds: 0
            })
          : date,
        day: getDate(date),
        enabled:
          isSameMonth(date, currentDate.value) &&
          !props.unavailableDates.some(d => isSameDay(d, date)) &&
          (!props.onlyFuture || isBefore(subDays(today, 1), date)) &&
          maxDateFilter &&
          (!props.onlyWorkingDays || getISODay(date) < 6),
        selected: model.value ? isSameDay(date, model.value) : false
      }
    }),
    7
  )
})

const months = computed(() => {
  const months = new Array(12).fill(null).map((_, i) => {
    const month = new Date(new Date().getFullYear(), i)
    return {
      label: format(month, 'MMM'),
      value: month.getMonth()
    }
  })
  if (
    currentDate.value.getFullYear() === today.getFullYear() &&
    props.onlyFuture
  ) {
    months.splice(0, today.getMonth())
  }
  return months
})

const month = computed({
  get() {
    if (props.showSelectors) {
      return currentDate.value.getMonth()
    } else {
      return format(currentDate.value, 'MMMM')
    }
  },
  set(value) {
    currentDate.value = setMonth(currentDate.value, value as number)
  }
})

const year = computed({
  get() {
    return currentDate.value.getFullYear()
  },
  set(value) {
    currentDate.value = setYear(currentDate.value, value)
  }
})

watch(model, value => {
  if (value) {
    currentDate.value = value
  }
})

function nextMonth() {
  currentDate.value = addMonths(currentDate.value, 1)
}

function previousMonth() {
  currentDate.value = subMonths(currentDate.value, 1)
}

function chunks(list: Day[], size: number) {
  const res = []
  for (let i = 0; i < list.length; i += size) {
    res.push(list.slice(i, i + size))
  }
  return res
}

function select(day: Day) {
  if (day.enabled) {
    model.value = setDay(model.value ?? day.date, day.date.getDay())
  }
}
</script>
