/* tslint:disable */
/* eslint-disable */
/**
 * POS API
 * Internal POS API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetCustomer200ResponseCustomer } from './GetCustomer200ResponseCustomer';
import {
    GetCustomer200ResponseCustomerFromJSON,
    GetCustomer200ResponseCustomerFromJSONTyped,
    GetCustomer200ResponseCustomerToJSON,
    GetCustomer200ResponseCustomerToJSONTyped,
} from './GetCustomer200ResponseCustomer';
import type { GetCustomer200ResponsePoints } from './GetCustomer200ResponsePoints';
import {
    GetCustomer200ResponsePointsFromJSON,
    GetCustomer200ResponsePointsFromJSONTyped,
    GetCustomer200ResponsePointsToJSON,
    GetCustomer200ResponsePointsToJSONTyped,
} from './GetCustomer200ResponsePoints';
import type { GetCustomer200ResponseTabsInner } from './GetCustomer200ResponseTabsInner';
import {
    GetCustomer200ResponseTabsInnerFromJSON,
    GetCustomer200ResponseTabsInnerFromJSONTyped,
    GetCustomer200ResponseTabsInnerToJSON,
    GetCustomer200ResponseTabsInnerToJSONTyped,
} from './GetCustomer200ResponseTabsInner';

/**
 * 
 * @export
 * @interface GetCustomer200Response
 */
export interface GetCustomer200Response {
    /**
     * 
     * @type {GetCustomer200ResponseCustomer}
     * @memberof GetCustomer200Response
     */
    customer: GetCustomer200ResponseCustomer;
    /**
     * 
     * @type {GetCustomer200ResponsePoints}
     * @memberof GetCustomer200Response
     */
    points: GetCustomer200ResponsePoints;
    /**
     * 
     * @type {Array<GetCustomer200ResponseTabsInner>}
     * @memberof GetCustomer200Response
     */
    tabs: Array<GetCustomer200ResponseTabsInner>;
}

/**
 * Check if a given object implements the GetCustomer200Response interface.
 */
export function instanceOfGetCustomer200Response(value: object): value is GetCustomer200Response {
    if (!('customer' in value) || value['customer'] === undefined) return false;
    if (!('points' in value) || value['points'] === undefined) return false;
    if (!('tabs' in value) || value['tabs'] === undefined) return false;
    return true;
}

export function GetCustomer200ResponseFromJSON(json: any): GetCustomer200Response {
    return GetCustomer200ResponseFromJSONTyped(json, false);
}

export function GetCustomer200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCustomer200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'customer': GetCustomer200ResponseCustomerFromJSON(json['customer']),
        'points': GetCustomer200ResponsePointsFromJSON(json['points']),
        'tabs': ((json['tabs'] as Array<any>).map(GetCustomer200ResponseTabsInnerFromJSON)),
    };
}

export function GetCustomer200ResponseToJSON(json: any): GetCustomer200Response {
    return GetCustomer200ResponseToJSONTyped(json, false);
}

export function GetCustomer200ResponseToJSONTyped(value?: GetCustomer200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'customer': GetCustomer200ResponseCustomerToJSON(value['customer']),
        'points': GetCustomer200ResponsePointsToJSON(value['points']),
        'tabs': ((value['tabs'] as Array<any>).map(GetCustomer200ResponseTabsInnerToJSON)),
    };
}

