<template>
  <transition name="fade-slide">
    <div
      v-if="open"
      class="fixed w-screen h-screen left-0 top-0 flex items-center justify-center z-50 bg-n-900/80 backdrop-blur-sm sm:py-4 sm:pb-[calc(var(--safe-area-inset-bottom)+1rem)] sm:pt-[calc(var(--safe-area-inset-top)+1rem)]"
    >
      <div
        class="flex flex-col bg-n-0 dark:bg-n-800 w-full h-full sm:max-h-full shadow-lg sm:rounded-2xl text-n-800 dark:text-n-0 relative overflow-hidden pb-safe sm:pb-0"
        :class="{
          'sm:w-[32rem] sm:max-h-[48rem]': size === 'small',
          'sm:w-[60rem] sm:max-h-[35rem]': size === 'large',
          'sm:h-auto': !fullHeight && !(screens.length > 0)
        }"
        @click.stop
      >
        <template v-if="screens.length > 0">
          <transition
            v-for="(screen, index) in screens"
            :key="screen.name"
            :name="transitionName"
          >
            <div
              v-if="activeScreen === index"
              :key="screen.name"
              class="w-full h-full absolute left-0 top-0 min-h-0"
            >
              <div
                class="absolute flex flex-col h-full w-full left-0 top-0 flex-1 overflow-y-auto"
              >
                <div class="flex flex-1 flex-col">
                  <l-modal-title
                    :title="screen.title"
                    :show-back="activeScreen > 0"
                    @back="activeScreen--"
                    @close="emit('close')"
                  />
                  <div class="px-6 overflow-y-auto">
                    <div v-if="screen.description" class="font-body">
                      {{ screen.description }}
                    </div>
                    <div class="pt-4">
                      <slot :name="screen.name" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="screen.buttonText || screen.secondaryButtonText"
                class="absolute right-0 left-0 bottom-0 bg-n-0 dark:bg-n-800 py-8 rounded-b-[1rem]"
              >
                <div
                  class="grid gap-2 px-4"
                  :class="[
                    screen.buttonText && screen.secondaryButtonText
                      ? 'grid-cols-2'
                      : 'grid-cols-1'
                  ]"
                >
                  <l-button
                    v-if="screen.secondaryButtonText"
                    :disabled="!secondaryButtonEnabled"
                    type="secondary"
                    class="h-12"
                    :class="{
                      'mx-auto w-[17.875rem]': !screen.buttonText
                    }"
                    @click="emit('secondaryAction')"
                  >
                    {{ screen.secondaryButtonText }}
                  </l-button>
                  <l-button
                    v-if="screen.buttonText"
                    :disabled="!buttonEnabled"
                    class="h-12"
                    :class="{
                      'mx-auto w-[17.875rem]': !screen.secondaryButtonText
                    }"
                    @click="emit('action')"
                  >
                    {{ screen.buttonText }}
                  </l-button>
                </div>
              </div>
            </div>
          </transition>
        </template>
        <template v-else>
          <div class="flex-1 grow relative min-h-0 flex flex-col h-full">
            <l-modal-title :title="title" @close="emit('close')" />
            <div class="flex flex-col h-full px-4 sm:px-6 overflow-y-auto">
              <div v-if="description" class="flex-none font-body">
                {{ description }}
              </div>
              <div class="pt-4 pb-2 flex-1">
                <slot />
              </div>
            </div>
          </div>
        </template>
        <div v-if="$slots.footer" class="py-4 px-4 sm:px-6">
          <slot name="footer" />
        </div>
        <div
          v-if="
            (buttonText && button) || (secondaryButtonText && secondaryButton)
          "
          class="p-4 sm:p-8 grid gap-2"
          :class="[button && secondaryButton ? 'grid-cols-2' : 'grid-cols-1']"
        >
          <l-button
            v-if="secondaryButtonText && secondaryButton"
            class="max-w-72 w-full h-12"
            :class="{
              'mx-auto': !(button && buttonText)
            }"
            :size="secondaryButtonSize"
            :type="secondaryButtonType"
            :disabled="!secondaryButtonEnabled"
            @click="emit('secondaryAction')"
          >
            {{ secondaryButtonText }}
          </l-button>
          <l-button
            v-if="buttonText && button"
            class="max-w-72 w-full h-12"
            :class="{
              'mx-auto': !(secondaryButtonText && secondaryButton)
            }"
            :size="buttonSize"
            :type="buttonType"
            :disabled="!buttonEnabled"
            @click="emit('action')"
          >
            {{ buttonText }}
          </l-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { defineModel, ref, watch } from 'vue'

import LButton from '../LButton.vue'
import LModalTitle from './LModalTitle.vue'

export type Screen = {
  name: string
  buttonText?: string
  secondaryButtonText?: string
  sectionTitle?: string
  title?: string
  description?: string
}

type Props = {
  open?: boolean
  size?: 'small' | 'large'
  button?: boolean
  buttonText?: string
  buttonEnabled?: boolean
  buttonSize?: 'small' | 'medium' | 'large'
  buttonType?: 'primary' | 'secondary' | 'link' | 'text'
  secondaryButton?: boolean
  secondaryButtonText?: string
  secondaryButtonEnabled?: boolean
  secondaryButtonSize?: 'small' | 'medium' | 'large'
  secondaryButtonType?: 'primary' | 'secondary' | 'link' | 'text'
  sectionTitle?: string
  title?: string
  description?: string
  screens?: Screen[]
  fullHeight?: boolean
}

withDefaults(defineProps<Props>(), {
  open: true,
  size: 'small',
  button: true,
  buttonText: '',
  buttonEnabled: true,
  buttonSize: 'small',
  buttonType: 'primary',
  secondaryButton: false,
  secondaryButtonText: '',
  secondaryButtonEnabled: true,
  secondaryButtonSize: 'small',
  secondaryButtonType: 'secondary',
  sectionTitle: '',
  title: '',
  description: '',
  screens: () => [],
  fullHeight: false
})

const emit = defineEmits<{
  action: []
  secondaryAction: []
  close: []
}>()

const activeScreen = defineModel<number>('activeScreen')

const transitionName = ref('forward')

watch(activeScreen, (newVal = 0, oldVal = 0) => {
  transitionName.value = newVal < oldVal ? 'backward' : 'forward'
  activeScreen.value = newVal
})
</script>

<style scoped>
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.3s ease;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.fade-slide-enter-to,
.fade-slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.backward-enter-active,
.backward-leave-active {
  transition: transform 0.3s ease;
}
.backward-leave-to {
  transform: translateX(100%);
}

.backward-enter-from {
  transform: translateX(-100%);
}

.forward-enter-active,
.forward-leave-active {
  transition: transform 0.3s ease;
}
.forward-leave-to {
  transform: translateX(-100%);
}

.forward-enter-from {
  transform: translateX(100%);
}
</style>
