import { parsePhoneNumberFromString } from 'libphonenumber-js'
import moment from 'moment'

import VATValidator from './VATValidator'

export const supportedCountries = ['ES', 'DE']

export type CurrencyFormat = 'EUR' | 'USD' | 'GBP'

export const colors = [
  '#FF7878',
  '#7142A0',
  '#D55283',
  '#35BF8D',
  '#E55934',
  '#4299E1',
  '#3A6890',
  '#21BECB',
  '#FFD166',
  '#795548',
  '#5F3C54'
] as const

export const allergens = [
  'gluten',
  'crustaceans',
  'eggs',
  'fish',
  'peanuts',
  'soy',
  'milk',
  'tree-nuts',
  'celery',
  'mustard',
  'sesame',
  'sulfites',
  'lupin',
  'molluscs'
] as const

export function currencyFormatters(
  currency: CurrencyFormat,
  milis = false
): Intl.NumberFormat {
  const config = {
    style: 'currency',
    currency: currency ?? 'EUR',
    maximumFractionDigits: milis ? 3 : 2
  } as const
  const formats = {
    EUR: 'es-ES',
    USD: 'en-EN',
    GBP: 'en-EN'
  } as const
  return new Intl.NumberFormat(formats[currency], config)
}

export function colorIsDark(hexcolor: string): boolean {
  const color = hexcolor.substr(1, 6)
  const r = parseInt(color.substring(0, 2), 16)
  const g = parseInt(color.substring(2, 4), 16)
  const b = parseInt(color.substring(4, 6), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq < 200
}

export function darken(color: string, percent = 10): string {
  if (!color) return '#1D1C48'
  let R = parseInt(color.substring(1, 3), 16)
  let G = parseInt(color.substring(3, 5), 16)
  let B = parseInt(color.substring(5, 7), 16)

  R = Math.min(Math.floor((R * (100 - percent)) / 100), 255)
  G = Math.min(Math.floor((G * (100 - percent)) / 100), 255)
  B = Math.min(Math.floor((B * (100 - percent)) / 100), 255)

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16)
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16)
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16)

  return '#' + RR + GG + BB
}

export function stringHashCode(string: string): number {
  let hash = 0,
    i,
    chr
  if (string.length === 0) return hash
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return Math.abs(hash)
}

export function log<T>(item: T): T {
  console.log(item) // eslint-disable-line no-console
  return item
}

export function getHashColor(string: string): string {
  return colors[stringHashCode(string) % colors.length]
}

export function getNthColor(n: number): string {
  return colors[n % colors.length]
}

export function round(num: number): number {
  return Math.round(num * 100) / 100
}

export function deepClone<T>(data: T): T {
  return JSON.parse(JSON.stringify(data))
}

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function currencyFilter(
  value: number,
  currency: CurrencyFormat = 'EUR',
  milis = false
): string {
  const decimalValue = milis ? value / 1000 : value / 100
  if (!['USD', 'GBP', 'EUR'].includes(currency)) {
    return currencyFormatters('EUR', milis).format(decimalValue)
  }
  return currencyFormatters(currency, milis).format(decimalValue)
}

export function currencySymbol(currency: CurrencyFormat = 'EUR'): string {
  return currencyFilter(0, currency)
    .replace(/\d|[,.]/g, '')
    .trim()
}

export function objectsAreEqual(
  initialObject: Record<string, any>,
  object: Record<string, any>
): boolean {
  if (!initialObject || !object) return false
  return Object.keys(object).reduce((acc, key) => {
    if (acc && initialObject[key] !== object[key]) acc = false
    return acc
  }, true)
}

export function formatNumber(phoneNumber: string): string {
  if (!phoneNumber) return phoneNumber
  const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'ES')
  return String(parsedNumber ? parsedNumber.number : phoneNumber)
}

export function debounce<T extends (...args: unknown[]) => void>(
  func: T,
  delay: number
): (...args: Parameters<T>) => void {
  let debounceTimer: NodeJS.Timeout
  return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => func.apply(this, args), delay)
  }
}

export function fuzzy(text: string, search: string): boolean {
  const [lText, lSearch] = [text, search].map(str => str.toLowerCase())
  let j = 0
  for (let i = 0; i < lText.length; i++) {
    if (lText[i] === lSearch[j]) j++
  }
  return j === search.length
}

export function formatEtaToRange(baseEta: number): string {
  const eta = baseEta || 15
  const etaRoundedToNearest5 = Math.round(eta / 5) * 5
  return etaRoundedToNearest5 + '-' + (etaRoundedToNearest5 + 10)
}

export function normalizeText(value: string): string {
  return value
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace("'", '')
}

export function isNullOrEmpty(stringInput: string | null | undefined): boolean {
  return stringInput == null || stringInput.trim() === ''
}

export function getPercentageDiff(
  current: number,
  past: number
): { line: string; color: string } | null {
  if (!current || !past) return null
  const percentage = Math.round((current / past) * 100) - 100
  return {
    line: `${percentage > 0 ? '+' : '-'} ${Math.abs(percentage)} %`,
    color: percentage > 0 ? 'text-semantic-green' : 'text-error'
  }
}

export function getCurrencyIcon(currencyCode: string): string {
  switch (currencyCode) {
    case 'USD':
      return 'dollar'
    case 'GBP':
      return 'pound'
    default:
      return 'euro'
  }
}

export function toKebabCase(text: string): string {
  return text
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase()
}

export function snakeToCamel(text: string): string {
  return text.replace(/([-_][a-z])/g, group =>
    group.toUpperCase().replace('-', '').replace('_', '')
  )
}

export function capitalize(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function roundMinutes(
  minutes: number,
  multiple: number,
  floor: boolean
): number {
  const remainder = minutes % multiple
  return floor ? minutes - remainder : minutes + (multiple - remainder)
}

export function taxIdIsValid(taxId: string): boolean {
  return VATValidator.isValid(taxId)
}

export function isIp(ip: string): boolean {
  const ipRegex = /^192\.168\.\d{1,3}\.\d{1,3}$/
  const ipRegex2 = /^10\.\d{1,3}\.\d{1,3}\.\d{1,3}$/
  const ipRegex3 = /^127\.0\.0\.1$/
  const ipRegex4 = /^172\.(1[6-9]|2[0-9]|3[0-1])\.\d{1,3}\.\d{1,3}$/

  const numbers = ip.split('.').map(Number)

  return (
    (ipRegex.test(ip) ||
      ipRegex2.test(ip) ||
      ipRegex3.test(ip) ||
      ipRegex4.test(ip)) &&
    numbers.every(number => number >= 0 && number <= 255)
  )
}

export function isUuid4(text: string): boolean {
  const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

  return uuidRegex.test(text)
}

export function isEmail(email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export function toChunks<T>(array: T[], chunkSize: number): T[][] {
  const chunks: T[][] = []
  for (let i = 0, j = array.length; i < j; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }
  return chunks
}

export async function poll({
  fn,
  n = 10,
  interval = 1000
}: {
  fn: () => Promise<any>
  n?: number
  interval?: number
}): Promise<any> {
  let i = 0
  while (i < n) {
    const result = await fn()
    if (result) {
      return result
    }
    i++
    await sleep(interval)
  }
  if (i === n) throw new Error('Polling timeout')
}

export function earliest(date1: string, date2: string): string {
  if (!date1) return date2
  if (!date2) return date1
  return moment(date1).isBefore(date2) ? date1 : date2
}

export function oldest(date1: string, date2: string): string {
  if (!date1) return date2
  if (!date2) return date1
  return moment(date1).isBefore(date2) ? date2 : date1
}

export function getSameLaboralDayFromLastYear(today: string): {
  oneYearAgoAsString: string
  oneYearAndOneDayAgoAsString: string
} {
  const oneYearAgo = moment(today)
    .subtract(1, 'years')
    .week(moment(today).week())
    .day(moment(today).day())
  const oneYearAndOneDayAgo = moment(oneYearAgo).subtract(1, 'day')

  return {
    oneYearAgoAsString: oneYearAgo.format(),
    oneYearAndOneDayAgoAsString: oneYearAndOneDayAgo.format()
  }
}

export function unique<T extends { id: string }>(items: T[]): T[] {
  const dict = items.reduce(
    (res, item) => {
      res[item.id] = item
      return res
    },
    {} as { [id: string]: T }
  )
  return Object.values(dict)
}

export function index<T extends Record<string, any>>(
  array: T[],
  indexValue: keyof T
): { [key: string]: T } {
  return array.reduce(
    (obj, item) => {
      if (item[indexValue] === undefined)
        throw new Error(`Key ${String(indexValue)} not found in array`)
      obj[item[indexValue]] = item
      return obj
    },
    {} as { [key: string]: T }
  )
}

export function indexList<T extends Record<string, any>>(
  array: T[],
  indexValue: keyof T
): { [key: string]: T[] } {
  return array.reduce(
    (obj, item) => {
      if (item[indexValue] === undefined)
        throw new Error(`Key ${String(indexValue)} not found in array`)
      if (!obj[item[indexValue]]) obj[item[indexValue]] = []
      obj[item[indexValue]].push(item)
      return obj
    },
    {} as { [key: string]: T[] }
  )
}

export function addBusinessDays(date: string, days: number): moment.Moment {
  const newDate = moment(date)
  while (days > 0) {
    newDate.add(1, 'days')
    if (newDate.isoWeekday() < 6) days--
  }
  return newDate
}

export function subtractBusinessDays(
  date: string | Date,
  days: number
): moment.Moment {
  const newDate = moment(date)
  while (days > 0) {
    newDate.subtract(1, 'days')
    if (newDate.isoWeekday() < 6) days--
  }
  return newDate
}

export function pick<T extends object, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Pick<T, K> {
  return keys.reduce(
    (acc, key) => {
      if (key in obj) {
        acc[key] = obj[key]
      }
      return acc
    },
    {} as Pick<T, K>
  )
}

export async function promiseAll<T>(
  promises: Promise<T>[],
  options?: { chunkSize: number }
): Promise<T[]> {
  const chunkSize = options?.chunkSize ?? 10
  const results: T[] = []
  const chunks = toChunks(promises, chunkSize)
  for (const chunk of chunks) {
    const chunkResults = await Promise.all(chunk)
    results.push(...chunkResults)
  }
  return results
}

export async function promiseAllSettled<T>(
  promises: Promise<T>[],
  options?: { chunkSize: number }
): Promise<PromiseSettledResult<T>[]> {
  const chunkSize = options?.chunkSize ?? 10
  const results: PromiseSettledResult<T>[] = []
  const chunks = toChunks(promises, chunkSize)
  for (const chunk of chunks) {
    const chunkResults = await Promise.allSettled(chunk)
    results.push(...chunkResults)
  }
  return results
}

export function isFuzzyMatch(target: string, query: string): boolean {
  const normalizedTarget = target.trim().toLowerCase()
  const normalizedQuery = query.trim().toLowerCase()
  let index = 0
  for (const char of normalizedTarget) {
    if (
      char.localeCompare(normalizedQuery[index], 'es', {
        sensitivity: 'base'
      }) === 0
    ) {
      index++
      if (index === normalizedQuery.length) {
        return true
      }
    }
  }
  return false
}

export function split<T>(
  array: T[],
  condition: (item: T) => boolean
): [T[], T[]] {
  return array.reduce(
    (acc, item) => {
      if (condition(item)) {
        acc[0].push(item)
      } else {
        acc[1].push(item)
      }
      return acc
    },
    [[], []] as [T[], T[]]
  )
}

export function isPastDateTime(date: Date): boolean {
  const now = moment()
  const dateTime = moment(date)

  return dateTime.isBefore(now)
}

export function isObject(value: unknown): boolean {
  return value !== null && typeof value === 'object' && !Array.isArray(value)
}
