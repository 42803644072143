import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { Howl } from 'howler'

import CashMachine from '@/integrations/cashmachine/cashmachine'
import { getSerial } from '@/integrations/SerialPlugin'
import { logger, rum } from '@/monitoring'
import { useConfigStore } from '@/store/config'
import { getUpdater } from '@/updater'

import { useUpdateVersion } from './plugins/updateVersion'
import { Device as DeviceType } from './types/device'

class App {
  private platform = Capacitor.getPlatform()
  private updater = getUpdater(this.platform)
  private componentMounted: boolean = false
  public isMobile: boolean = false
  public isElectron: boolean = false
  public isDataphone: boolean = false
  public serial: any | null = null
  private ipcRenderer: any

  constructor() {
    this.componentMounted = false
    this.isMobile = window.innerWidth <= 640

    try {
      this.ipcRenderer = window.require('electron').ipcRenderer
      this.isElectron = true
    } catch {
      this.isElectron = false
    }
    this.initDeviceSerial()
  }

  notifyAppReady() {
    this.updater.notifyAppReady()
    this.updater.on('updateReady', () => {
      this.mountUpdateModalComponent()
    })
  }

  async getDeviceInfo(): Promise<DeviceType> {
    const deviceInfo = await Device.getInfo()
    const version = await this.updater.getVersion()
    if (this.isElectron) {
      try {
        await this.ipcRenderer.invoke('deviceId')
      } catch (e) {
        logger.error('Failed to get device id')
        rum.addError(e)
      }
    }
    const deviceId = await Device.getId().then(res => res.identifier)

    return {
      id: deviceId,
      platform: this.platform,
      model: deviceInfo.model,
      os: deviceInfo.operatingSystem,
      osVersion: deviceInfo.osVersion,
      appVersion: version.name,
      appBuild: version.build
    }
  }

  async checkForUpdates(channel?: string) {
    const device = await this.getDeviceInfo()
    this.updater.checkUpdates({ device, channel })
  }

  async initDeviceSerial() {
    try {
      const info = await Device.getInfo()
      if (info.model === 'Saturn1000F2') {
        this.isDataphone = true
        this.serial = await getSerial()
      }
    } catch {
      this.serial = null
    }
  }

  quit() {
    this.ipcRenderer.invoke('window:close')
  }

  minimize() {
    this.ipcRenderer.invoke('window:minimize')
  }

  mountUpdateModalComponent() {
    if (this.componentMounted) return
    this.componentMounted = true
    const updateModal = useUpdateVersion()
    updateModal({
      onConfirm: () => this.confirmUpdate()
    })
  }

  async confirmUpdate() {
    if (CashMachine.isEnabled()) {
      try {
        await CashMachine.close()
      } catch {
        logger.warn('Failed to close cash machine')
      }
    }
    this.updater.installUpdate()
  }

  playSound(name: string) {
    const config = useConfigStore()
    if (!config.mute) {
      const sound = new Howl({
        src: [`${name}.mp3`]
      })
      sound.play()
    }
  }
}

export default new App()
