import { registerPlugin } from '@capacitor/core'

import { Tracker } from '@last/core-ui/paprika'

export type AdyenChargeResponse = {
  SaleToPOIResponse: {
    PaymentResponse: {
      PaymentReceipt: Array<{
        DocumentQualifier: string
        RequiredSignatureFlag: boolean
        OutputContent: {
          OutputText: Array<{
            CharacterStyle?: string
            EndOfLineFlag: boolean
            Text: string
          }>
          OutputFormat: string
        }
      }>
      POIData: {
        POIReconciliationID: string
        POITransactionID: {
          TransactionID: string
          TimeStamp: string
        }
      }
      Response: {
        AdditionalResponse: string
        Result: string
      }
      SaleData: {
        SaleTransactionID: {
          TimeStamp: string
          TransactionID: string
        }
      }
      PaymentResult: {
        OnlineFlag: boolean
        AmountsResp: {
          AuthorizedAmount: number
          Currency: string
        }
        PaymentInstrumentData: {
          PaymentInstrumentType: string
          CardData: {
            MaskedPan: string
            PaymentBrand: string
            SensitiveCardData: {
              CardSeqNumb: string
              ExpiryDate: string
            }
            EntryMode: Array<string>
          }
        }
        PaymentAcquirerData: {
          AcquirerPOIID: string
          ApprovalCode: string
          AcquirerTransactionID: {
            TimeStamp: string
            TransactionID: string
          }
          MerchantID: string
        }
      }
    }
    MessageHeader: {
      MessageClass: string
      SaleID: string
      ServiceID: string
      POIID: string
      MessageCategory: string
      MessageType: string
      ProtocolVersion: string
    }
  }
}

interface AdyenIPP {
  charge({ amount }: { amount: number }): Promise<AdyenChargeResponse>
  init({ authToken }: { authToken: string }): void
}

const plugin = registerPlugin<AdyenIPP>('AdyenIPPPlugin')

class AdyenIPPPluginWithAvailability implements AdyenIPP {
  available = false

  charge({ amount }: { amount: number }): Promise<AdyenChargeResponse> {
    return plugin.charge({ amount })
  }

  async init({ authToken, tracker }: { authToken: string; tracker: Tracker }) {
    try {
      await plugin.init({ authToken })
      tracker.track('nfcpayment:init', {}, { nfcPaymentAvailable: true })
      this.available = !!(await tracker.getFeatureFlag('nfc-payment'))
    } catch {
      // Plugin not available
    }
  }

  isAvailable() {
    return this.available
  }
}

export const AdyenIPPPlugin = new AdyenIPPPluginWithAvailability()
