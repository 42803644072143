/* tslint:disable */
/* eslint-disable */
/**
 * POS API
 * Internal POS API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetCustomer200ResponseTabsInnerProductsInner } from './GetCustomer200ResponseTabsInnerProductsInner';
import {
    GetCustomer200ResponseTabsInnerProductsInnerFromJSON,
    GetCustomer200ResponseTabsInnerProductsInnerFromJSONTyped,
    GetCustomer200ResponseTabsInnerProductsInnerToJSON,
    GetCustomer200ResponseTabsInnerProductsInnerToJSONTyped,
} from './GetCustomer200ResponseTabsInnerProductsInner';

/**
 * 
 * @export
 * @interface GetCustomer200ResponseTabsInner
 */
export interface GetCustomer200ResponseTabsInner {
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseTabsInner
     */
    id: string;
    /**
     * 
     * @type {Array<GetCustomer200ResponseTabsInnerProductsInner>}
     * @memberof GetCustomer200ResponseTabsInner
     */
    products: Array<GetCustomer200ResponseTabsInnerProductsInner>;
    /**
     * 
     * @type {number}
     * @memberof GetCustomer200ResponseTabsInner
     */
    total: number;
    /**
     * 
     * @type {Date}
     * @memberof GetCustomer200ResponseTabsInner
     */
    creationTime: Date;
}

/**
 * Check if a given object implements the GetCustomer200ResponseTabsInner interface.
 */
export function instanceOfGetCustomer200ResponseTabsInner(value: object): value is GetCustomer200ResponseTabsInner {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('products' in value) || value['products'] === undefined) return false;
    if (!('total' in value) || value['total'] === undefined) return false;
    if (!('creationTime' in value) || value['creationTime'] === undefined) return false;
    return true;
}

export function GetCustomer200ResponseTabsInnerFromJSON(json: any): GetCustomer200ResponseTabsInner {
    return GetCustomer200ResponseTabsInnerFromJSONTyped(json, false);
}

export function GetCustomer200ResponseTabsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCustomer200ResponseTabsInner {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'products': ((json['products'] as Array<any>).map(GetCustomer200ResponseTabsInnerProductsInnerFromJSON)),
        'total': json['total'],
        'creationTime': (new Date(json['creationTime'])),
    };
}

export function GetCustomer200ResponseTabsInnerToJSON(json: any): GetCustomer200ResponseTabsInner {
    return GetCustomer200ResponseTabsInnerToJSONTyped(json, false);
}

export function GetCustomer200ResponseTabsInnerToJSONTyped(value?: GetCustomer200ResponseTabsInner | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'products': ((value['products'] as Array<any>).map(GetCustomer200ResponseTabsInnerProductsInnerToJSON)),
        'total': value['total'],
        'creationTime': ((value['creationTime']).toISOString()),
    };
}

