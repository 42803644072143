<template>
  <tippy
    ref="tippyRef"
    class="w-full"
    :interactive="true"
    :arrow="false"
    trigger="click"
    placement="bottom"
    :duration="100"
    theme="timepicker"
  >
    <template #default="{ state }">
      <div class="w-full relative">
        <div
          data-testid="time-picker"
          class="flex rounded-xl justify-between border border-transparent items-center cursor-pointer min-w-[200px]"
          :class="[
            ...sizeClasses,
            ...paddingClasses,
            ...backgroundColorsClasses,
            ...focusClasses,
            ...textColorsClasses
          ]"
        >
          <l-icon name="calendar" class="mr-2 text-n-200 dark:text-n-0" />
          <div class="flex-1 whitespace-nowrap">
            <div v-if="time[0] && time[1]">{{ time[0] }}:{{ time[1] }}</div>
            <div v-else class="ellipsis text-n-200">
              {{ placeholder }}
            </div>
          </div>
          <l-icon
            name="bracket-down"
            class="transition-all duration-300"
            :class="{
              'rotate-180': state.isVisible
            }"
          />
        </div>
      </div>
    </template>
    <template #content>
      <div
        class="w-full border border-n-50 dark:border-n-900 rounded-xl p-2 flex items-center gap-2 bg-n-0 dark:bg-n-800 z-10"
      >
        <number-picker
          :size="pickerSize ?? size"
          v-model="time[0]"
          :max="24"
          :step="hourStep"
          :default="defaultValue[0]"
          @keyup="onHourKeyup"
        />
        <div class="font-body select-none text-n-800 dark:text-n-0">:</div>
        <number-picker
          ref="minutesEl"
          :size="pickerSize ?? size"
          v-model="time[1]"
          :max="60"
          :step="minuteStep"
          :default="defaultValue[1]"
        />
      </div>
    </template>
  </tippy>
</template>

<script setup lang="ts">
import { set } from 'date-fns'
import { computed, defineModel, ref, useTemplateRef, watch } from 'vue'
import { Tippy } from 'vue-tippy'

import { LIcon } from '@last/core-ui/paprika'

import {
  useBackgroundColorsClasses,
  useFocusClasses,
  usePaddingClasses,
  useSizeClasses,
  useTextColorsClasses
} from '../classes'
import type { Size } from '../types'
import NumberPicker from './NumberPicker.vue'

type Props = {
  /**
   * Step for the hours
   * @default 1
   */
  hourStep?: number
  /**
   * Step for the minutes
   * @default 1
   */
  minuteStep?: number
  /**
   * Placeholder text on the text field
   */
  placeholder?: string
  /**
   * Size of the field size
   * @default small
   */
  size?: Size
  /*
   * Size of the picker
   * By default is the same as the field size
   */
  pickerSize?: Size
  /*
   * Default time in HH:mm format
   */
  default?: string
}

const props = withDefaults(defineProps<Props>(), {
  hourStep: 1,
  minuteStep: 1,
  placeholder: '',
  size: 'small',
  pickerSize: undefined,
  default: undefined
})

const [model, modifier] = defineModel<string | Date | null, 'date'>({
  default: null,
  set: value => {
    if (!value) {
      return null
    }
    if (modifier.date) {
      return new Date(value)
    }
    return value
  }
})

const time = ref<[string | null, string | null]>([null, null])

const tippyRef = useTemplateRef<typeof Tippy>('tippyRef')
const minutesEl = useTemplateRef('minutesEl')

const sizeClasses = computed(() => useSizeClasses(props.size))
const paddingClasses = computed(() => usePaddingClasses(props.size))
const backgroundColorsClasses = computed(() => useBackgroundColorsClasses())
const focusClasses = computed(() => useFocusClasses())
const textColorsClasses = computed(() => useTextColorsClasses())

const defaultValue = computed<[string, string] | [null, null]>(() => {
  if (props.default) {
    return props.default.split(':') as [string, string]
  }
  return [null, null]
})

watch(
  model,
  value => {
    const currentDate = value
    if (currentDate) {
      const newValue = extractTime(currentDate)
      const actualValue = time.value
      if (newValue[0] !== actualValue[0] || newValue[1] !== actualValue[1]) {
        time.value = newValue
      }
    }
  },
  {
    immediate: true
  }
)

watch(
  time,
  value => {
    const defaultTime = defaultValue.value
    if ((value[0] && value[1]) || (defaultTime[0] && defaultTime[1])) {
      model.value = set(model.value ?? new Date(), {
        hours: parseInt(value[0] ?? defaultTime[0]!),
        minutes: parseInt(value[1] ?? defaultTime[1]!),
        seconds: 0,
        milliseconds: 0
      }).toISOString()
    } else {
      model.value = null
    }
  },
  {
    deep: true
  }
)

function onHourKeyup() {
  const rawValue = time.value[0]
  if (!rawValue) return
  const value = parseInt(rawValue)
  if (value >= 10 || value.toString().length === 2) {
    minutesEl.value?.focus()
  }
}

function extractTime(rawValue: string | Date) {
  const value = new Date(rawValue)
  return [value.getHours(), value.getMinutes()].map(v =>
    v.toString().padStart(2, '0')
  ) as [string, string]
}
</script>

<style>
@import 'tippy.js/dist/tippy.css';

.tippy-box[data-theme~='timepicker'] {
  background: none !important;
}

.tippy-content[data-theme~='timepicker'] {
  padding: 0;
}

.tippy-box[data-theme~='timepicker'] .tippy-content {
  padding: 0;
}

.tippy-box[data-theme~='timepicker'][data-animation='fade'][data-state='hidden'] {
  opacity: 0;
}
</style>
