<template>
  <l-modal
    v-if="!cancelling"
    :title="$t('cash-machine-status.title')"
    :button-text="$t('ctas.cancel')"
    @close="cancel"
    @action="cancel"
  >
    <CalculatorDisplay
      :change="'0'"
      :pending="currency(amount)"
      :mode="CalculatorDisplayMode.PENDING"
      :to-pay="currency(inputAmount)"
    />
  </l-modal>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { LModal, useDialog } from '@last/core-ui/paprika'

import { useMoney } from '@/composables/useMoney'

import CalculatorDisplay from './Calculator/CalculatorDisplay.vue'
import { CalculatorDisplayMode } from './Calculator/CalculatorUtils'

const dialog = useDialog()
const { t } = useI18n()
const { currency } = useMoney()

const props = defineProps<{
  inputAmount: number
  amount: number
  onCancel: () => Promise<void>
}>()

const cancelling = ref(false)

async function cancel() {
  cancelling.value = true
  const dialogInstance = dialog({
    icon: 'status',
    iconAnimation: 'animate-spin',
    title: t('cash-machine-status.cancelling'),
    hiddenClose: true,
    noLabels: true
  })
  await props.onCancel()
  dialogInstance?.close()
}
</script>
