/* tslint:disable */
/* eslint-disable */
/**
 * POS API
 * Internal POS API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetCouriers200ResponseInner,
} from '../models/index';
import {
    GetCouriers200ResponseInnerFromJSON,
    GetCouriers200ResponseInnerToJSON,
} from '../models/index';

/**
 * 
 */
export class CouriersApi extends runtime.BaseAPI {

    /**
     */
    async getCouriersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetCouriers200ResponseInner>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/couriers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCouriers200ResponseInnerFromJSON));
    }

    /**
     */
    async getCouriers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetCouriers200ResponseInner>> {
        const response = await this.getCouriersRaw(initOverrides);
        return await response.value();
    }

}
