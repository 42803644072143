<template>
  <l-modal
    :title="
      internalCustomer.id
        ? $t('new-delivery.client-profile')
        : $t('new-delivery.new-client')
    "
    :button-text="$t('ctas.continue')"
    :button-enabled="canContinue"
    @action="checkUserForm()"
    @close="$emit('close')"
  >
    <client-info
      v-model:can-continue="clientPanelCanContinue"
      v-model:customer="internalCustomer"
      :is-delivery="isDelivery"
    />

    <delivery-address-picker
      v-if="isDelivery"
      v-model="internalCustomer.addresses"
      :hide-new-address-cta="isEdit"
      :address-missing="deliveryError"
      @delivery-address-selected="onAddressSelected"
    />

    <last-customer-tabs
      :last-customer-tabs="lastCustomerTabs"
      v-model:tab="internalTab"
    />
  </l-modal>
</template>

<script setup lang="ts">
import { v4 as uuid } from 'uuid'
import { computed, onMounted, ref } from 'vue'

import { LModal } from '@last/core-ui/paprika'

import { Address } from '@/types'

import ClientInfo from './ClientDetails/ClientInfo.vue'
import DeliveryAddressPicker from './ClientDetails/DeliveryAddressPicker.vue'
import LastCustomerTabs from './ClientDetails/LastCustomerTabs.vue'

const props = withDefaults(
  defineProps<{
    tab: any
    isEdit?: boolean
    customer: any
    delivery?: Address
    lastCustomerTabs?: any[]
  }>(),
  {
    isEdit: false,
    customer: () => ({}),
    delivery: undefined,
    lastCustomerTabs: undefined
  }
)

const emit = defineEmits(['close', 'continue'])

const internalCustomer = ref<any>({})
const internalDelivery = ref<Address | null>(null)
const internalTab = ref<any>({})

// Form validation
const deliveryError = ref(false)
const phoneError = ref(false)

/// NEW
const clientPanelCanContinue = ref(false)

onMounted(() => {
  internalCustomer.value = { ...props.customer }
  internalDelivery.value = props.delivery || null
  internalTab.value = { ...internalTab.value, ...props.tab }
  if (!props.isEdit) internalTab.value.id = uuid()
})

const canContinue = computed(() => {
  if (isDelivery.value) {
    return (
      !!internalCustomer.value.addresses &&
      internalCustomer.value.addresses.length > 0 &&
      !!internalDelivery.value &&
      clientPanelCanContinue.value
    )
  }
  return clientPanelCanContinue.value
})

const isDelivery = computed(() => {
  return ['delivery', 'ownDelivery'].includes(internalTab.value.pickupType)
})

const isOwnDelivery = computed(() => {
  return internalTab.value.pickupType === 'ownDelivery'
})

const isValidUserForm = computed(() => {
  return isDelivery.value
    ? internalCustomer.value.addresses &&
        internalCustomer.value.addresses.length > 0
    : isOwnDelivery.value
      ? !!internalCustomer.value.phoneNumber
      : true
})

function onAddressSelected(address: Address) {
  internalDelivery.value = address
}

function checkUserForm() {
  deliveryError.value = false
  phoneError.value = false
  if (
    isDelivery.value &&
    (!internalCustomer.value.addresses ||
      internalCustomer.value.addresses.length < 1)
  ) {
    deliveryError.value = true
  }
  if (isOwnDelivery.value && !internalCustomer.value.phoneNumber) {
    phoneError.value = true
  }
  if (isValidUserForm.value) {
    emit('continue', {
      customer: internalCustomer.value,
      address: internalDelivery.value,
      tab: internalTab.value
    })
  }
}
</script>
