<template>
  <div class="flex flex-col justify-center h-screen pb-safe bg-n-800 pb-safe">
    <top-bar show-close @close="handleClose">
      <template #left>
        <div class="font-heading font-bold text-xl">
          {{ product.name }}
        </div>
      </template>
    </top-bar>
    <div class="px-4 mt-6 flex-1 flex flex-col gap-4 min-h-0" @click.stop>
      <div class="flex justify-between w-full">
        <div class="text-heading text-n-0 text-base font-medium">
          {{ currency(product.price || product.priceImpact) }}
        </div>
        <quantity-selector
          v-if="showQuantity"
          :quantity="quantity"
          @update:quantity="updateQuantity"
        />
      </div>

      <l-select
        v-model="course"
        :placeholder="$t('tabs.courses')"
        :size="'medium'"
        :options="allCourses"
      />

      <div class="overflow-x-auto scrolling-touch shrink-0">
        <l-tab-list
          v-model="selectedTabId"
          :tabs="tabs"
          type="primary"
          :extend-line="true"
        />
      </div>

      <modifier-group-component
        v-if="modifierGroup.id === selectedTabId"
        :key="modifierGroup.name"
        v-model:modifiers="selectedModifiers[indexOfSelectedModifier]"
        :modifier-group="modifierGroup"
        @change="tryGotoNext"
      />
      <open-modifiers-component
        v-if="selectedTabId === TAB_OPEN_ATTRIBUTE"
        :open-modifiers="openModifiers"
        @create="handleSaveOpenModifier"
        @delete="deleteOpenModifier"
      />
      <template v-if="selectedTabId === TAB_COMMENT">
        <l-field :label="$t('ordering.comments')">
          <l-input
            v-model="comments"
            type="textarea"
            class="w-full"
            :placeholder="$t('ordering.comments')"
            maxlength="255"
          />
        </l-field>
      </template>
    </div>
    <div class="pb-4 px-4 pt-4 shrink-0">
      <l-button
        class="w-full"
        :disabled="!isMinimumComplete"
        @click="saveProduct"
      >
        {{ $t('ordering.add') }}</l-button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { v4 as uuid } from 'uuid'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import {
  LButton,
  LField,
  LInput,
  LSelect,
  LTabList
} from '@last/core-ui/paprika'
import ProductPriceCalculator from '@last/core/src/productPriceCalculator'

import ModifierGroupComponent from '@/components/ordering/modifiers/ModifierGroup.vue'
import OpenModifiersComponent from '@/components/ordering/modifiers/OpenModifiers.vue'
import { useProductEditor } from '@/components/ordering/modifiers/useProductEditor'
import QuantitySelector from '@/components/ordering/QuantitySelector.vue'
import { useMoney } from '@/composables/useMoney'
import TopBar from '@/mobile/components/TopBar.vue'
import { useTabsStore } from '@/store/tabs'
import type { Product } from '@/types'

const TAB_COMMENT = 'TAB_COMMENT'
const TAB_OPEN_ATTRIBUTE = 'TAB_OPEN_ATTRIBUTE'

type Props = {
  product: Product
  showQuantity?: boolean
  showCourse?: boolean
  selectedSeat?: number
  emitSave?: boolean
  emitClose?: boolean
}

const { product, emitClose, emitSave, selectedSeat } = defineProps<Props>()

const tabsStore = useTabsStore()

const { addProduct, updateProductModifiers, updateCourse } = tabsStore

const router = useRouter()
const route = useRoute()
const { currency } = useMoney()
const saveDisabled = ref(false)

const emit = defineEmits(['close', 'saveProduct'])

const {
  quantity,
  course,
  comments,
  selectedModifiers,
  openModifiers,
  selectedTabId,
  indexOfSelectedModifier,
  tabs,
  modifierGroup,
  allCourses,
  allSelectedModifiers,
  isMinimumComplete,
  deleteOpenModifier,
  updateQuantity,
  updateSelectedModifiers
} = useProductEditor(product)

const tabId = route.params.tabId as string

onMounted(() => {
  if (!product) {
    router.replace({
      name: 'orderManagement',
      params: { tabId: tabId }
    })
    return
  }
  updateSelectedModifiers()
})

const handleClose = () => {
  if (emitClose) {
    emit('close')
  } else {
    router.go(-1)
  }
}

function handleSaveOpenModifier(modifier: {
  name: string
  priceImpact: number
}) {
  openModifiers.value.push({
    id: uuid(),
    ...modifier
  })
}

const saveProduct = () => {
  if (saveDisabled.value) return
  saveDisabled.value = true
  const productToSave = {
    ...product,
    quantity: quantity.value,
    modifiers: allSelectedModifiers.value,
    comments: comments.value
  }
  if (emitSave) {
    emit('saveProduct', productToSave)
    return
  } else {
    const productPricing =
      ProductPriceCalculator.calculateProductPricing(productToSave)
    if (product.id) {
      updateProductModifiers({
        productId: productToSave.id,
        modifiers: productToSave.modifiers,
        comments: productToSave.comments,
        productPricing
      })
      if (course.value) {
        updateCourse({
          productId: product.id,
          course: course.value
        })
      }
    } else {
      addProduct({
        tabId: tabId,
        seat: selectedSeat,
        product: {
          ...productToSave,
          ...productPricing
        }
      })
    }
    emit('close')
  }
}

function tryGotoNext([selected, max]: [number, number]) {
  if (max && selected === max) {
    const selectedTabIdx = tabs.value.findIndex(
      tab => tab.id === selectedTabId.value
    )
    if (selectedTabIdx < tabs.value.length) {
      selectedTabId.value = tabs.value[selectedTabIdx + 1].id
    }
  }
}
</script>
