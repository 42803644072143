import { EmptySeparator, InputLine, Label } from './components.js'

function input(label: string, lines = 1) {
  return [
    new Label(label, {
      style: 'bold 14px',
      lineHeight: 14
    }),
    new EmptySeparator(),
    ...Array(lines)
      .fill(0)
      .map(() => new InputLine({ size: 16 }))
  ]
}

export function hospitality(enabled: boolean) {
  if (!enabled) return []
  return [
    ...input('Wer war dabei? (Bewirtete Personen)', 2),
    ...input("Was gab's zu feiern? (Anlass d. Bewirtung)", 2),
    ...input('Aufwendungen inkl. Trinkgeld', 1),
    ...input('bei Bewirtung in Restaurant', 1),
    ...input('in anderen Fällen', 1),
    ...input('Unterschrift des Gastes', 1)
  ]
}
