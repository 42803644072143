<template>
  <teleport to="#appRoot">
    <l-modal
      :title="$t('bill-discount.title-plural')"
      :button-enabled="!wrongAmount"
      :button-text="$t('bill-discount.apply-discount')"
      :open="isOpen"
      @action="save"
      @close="close"
    >
      <l-field :label="$t('bill-discount.select-discount-type')">
        <l-select v-model="selectedOption" :options="discountTypes" />
      </l-field>

      <div v-if="selectedOption === 'promotion'">
        <div v-if="promotionsWithoutPoints.length > 0" class="mb-8">
          <div class="flex flex-row font-title text-xl font-bold">
            {{ $t('bill-discount.no-points-discounts') }}
          </div>
          <div
            class="flex items-center mt-3 overflow-scroll scrolling-touch gap-3"
          >
            <promotion-item
              v-for="promotion in promotionsWithoutPoints"
              :key="promotion.id"
              :promotion="promotion"
              :selected="editingDiscount.promotionId === promotion.id"
              @click="promotionSelected(promotion)"
            />
          </div>
        </div>
        <div v-if="promotionsWithPoints.length > 0">
          <div class="flex flex-row font-title text-xl font-bold">
            <div>{{ $t('bill-discount.points-discounts') }} -</div>
            <div class="pl-1 text-g-400">
              {{ $t('bill-discount.available-points') }}:
              {{ customerPoints }}
            </div>
          </div>
          <div
            class="flex items-center mt-3 overflow-scroll scrolling-touch gap-3"
          >
            <promotion-item
              v-for="promotion in promotionsWithPoints"
              :key="promotion.id"
              :promotion="promotion"
              :selected="editingDiscount.promotionId === promotion.id"
              @click="promotionSelected(promotion)"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <l-field :label="$t('bill-discount.quantity')">
          <l-input
            v-if="editingDiscount.type === 'currency'"
            v-model.currency="editingDiscount.amount"
            type="number"
            :min="0"
            :icon="discountIcon"
          />
          <l-input
            v-else
            v-model.number="editingDiscount.amount"
            type="number"
            :min="0"
            :icon="discountIcon"
          />
        </l-field>

        <l-field :label="$t('bill-discount.concept')">
          <l-input v-model="editingDiscount.concept" type="textarea" />
        </l-field>
      </div>
    </l-modal>
  </teleport>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { storeToRefs } from 'pinia'
import { computed, defineModel, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { PromotionFilter } from '@last/core'
import { LField, LInput, LModal, LSelect } from '@last/core-ui/paprika'

import { getCustomerPromotions } from '@/api/customers'
import PromotionItem from '@/components/checkout/PromotionItem.vue'
import { useTabs } from '@/composables/useTabs'
import { useAuthStore } from '@/store/auth'
import { useConfigStore } from '@/store/config'
import { usePromotionsStore } from '@/store/promotions'
import type { Discount, Promotion } from '@/types'

const { t } = useI18n()

interface Props {
  originalTotal: number
  disabled?: boolean
  tabId: string
  size?: 'small' | 'medium'
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  size: 'medium'
})

const discount = defineModel<Discount>('discount')

const isOpen = defineModel<boolean>('isOpen')
const emit = defineEmits(['close', 'cancel'])

const authStore = useAuthStore()
const promotionsStore = usePromotionsStore()
const configStore = useConfigStore()

const { locationId } = storeToRefs(authStore)
const { getPromotionsWithPoints, getPromotionsWithoutPoints } =
  storeToRefs(promotionsStore)
const { currencyIcon } = storeToRefs(configStore)

const tabId = computed(() => props.tabId)
const { tab, customerId, total } = useTabs(tabId)

const customerPoints = ref(0)
const editingDiscount = ref<Discount>({
  type: 'percentage',
  amount: 0,
  concept: '',
  global: true
})
const customerRestrictedPromotions = ref<Promotion[]>([])
const customerUsedPromotions = ref<Promotion[]>([])
const selectedOption = ref<string>()

const wrongAmount = computed(() => {
  if (editingDiscount.value.type === 'currency') {
    if (editingDiscount.value.amount > props.originalTotal) {
      return true
    }
  } else if (editingDiscount.value.type === 'percentage') {
    const amount = parseInt(String(editingDiscount.value.amount))
    if (amount < 0 || amount > 100 || isNaN(amount)) return true
  }
  return false
})

const promotionsWithPoints = computed(() => {
  return getPromotionsWithPoints.value
    .concat(customerRestrictedPromotions.value)
    .filter(promotion => {
      return (
        checkIfPromotionCanBeShown(promotion) &&
        promotion.pointsExpense &&
        promotion.pointsExpense <= customerPoints.value
      )
    })
})

const promotionsWithoutPoints = computed(() => {
  return getPromotionsWithoutPoints.value
    .concat(customerRestrictedPromotions.value)
    .filter(promotion => {
      return checkIfPromotionCanBeShown(promotion)
    })
})

const isManualPromotion = computed(() => {
  return ![
    ...promotionsWithPoints.value,
    ...promotionsWithoutPoints.value
  ].some(promotion => {
    return promotion.id === discount.value?.promotionId
  })
})

const discountTypes = computed(() => {
  const types = [
    {
      value: 'percentage',
      label: t('bill-discount.percentage')
    },
    {
      value: 'currency',
      label: t('bill-discount.cash')
    }
  ]
  if (
    promotionsWithPoints.value.length > 0 ||
    promotionsWithoutPoints.value.length > 0
  ) {
    types.push({
      value: 'promotion',
      label: t('bill-discount.promotion')
    })
  }
  return types
})

function close() {
  emit('close')
  isOpen.value = false
}

const promotionSelected = (promotion: Promotion) => {
  if (editingDiscount.value.promotionId === promotion.id) {
    editingDiscount.value = {
      type: 'percentage',
      amount: 0,
      global: true
    }
  } else {
    editingDiscount.value = {
      type: promotion.discountType,
      amount: promotion.discountAmount,
      promotionId: promotion.id,
      pointsExpense: promotion.pointsExpense,
      promotionName: promotion.name,
      freeDelivery: promotion.freeDelivery,
      global: true
    }
  }
}

const save = async () => {
  discount.value = { ...editingDiscount.value }
  isOpen.value = false
}

const checkIfPromotionCanBeShown = (promotion: Promotion) => {
  const isNotProductOrCategory = !promotion.products && !promotion.categories

  let isCorrectAmount = true
  if (promotion.discountType === 'currency') {
    isCorrectAmount = promotion.discountAmount! <= props.originalTotal
  }
  const isCorrectType = ['currency', 'percentage'].includes(
    promotion.discountType
  )

  const today = format(new Date(), 'eeee', { locale: enUS }).toLowerCase()

  return (
    isNotProductOrCategory &&
    isCorrectType &&
    isCorrectAmount &&
    new PromotionFilter(
      total.value,
      locationId.value,
      today,
      tab.value.pickupType,
      customerId.value,
      customerUsedPromotions.value
    ).isPromotionValid(promotion)
  )
}

onMounted(async () => {
  if (discount.value) {
    editingDiscount.value = { ...discount.value }
    if (isManualPromotion.value) {
      selectedOption.value = editingDiscount.value.type
    } else {
      selectedOption.value = 'promotion'
    }
  } else {
    selectedOption.value = 'percentage'
  }
  if (customerId.value) {
    const response = await getCustomerPromotions(customerId.value)
    customerPoints.value = response.points
    customerRestrictedPromotions.value = response.customerPromotions
    customerUsedPromotions.value = response.customerUsedPromotions
  }
})

const discountIcon = computed(() => {
  if (editingDiscount.value.type === 'percentage') {
    return 'percentage'
  }
  return `currencies/${currencyIcon.value}`
})

watch(
  selectedOption,
  () => {
    if (
      selectedOption.value === discount.value?.type &&
      isManualPromotion.value
    ) {
      editingDiscount.value = { ...discount.value }
    } else if (selectedOption.value !== 'promotion') {
      editingDiscount.value = {
        type: selectedOption.value,
        amount: 0,
        concept: '',
        global: true
      }
    }
  },
  { immediate: true }
)
</script>
