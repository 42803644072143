import { inject } from 'vue'
import type { Plugin } from 'vue'
import type { Router } from 'vue-router'

import { ConsoleTracker } from './consoleTracker'

const TRACKER_KEY = 'tracker'

export interface UserData {
  id: string
  userId?: string
  name?: string
  location_id?: string
  organization_id?: string
}

export interface Tracker {
  identify(userData: UserData): void
  track(
    eventName: string,
    eventProperties?: Record<string, any>,
    userProperties?: Record<string, any>
  ): void
  /**
   * Function to get the value of a feature flag
   * @param name string of the feature flag
   * @returns the value of the feature flag, false if it doesn't exist
   */
  getFeatureFlag(name: string): Promise<boolean | string | undefined> | boolean
  /**
   * Function to check if a feature flag exists
   * @param name string of the feature flag
   * @returns true if the feature flag exists, false otherwise
   */
  hasFeatureFlag(name: string): Promise<boolean> | boolean
  clear(): void
}

export const trackerPlugin: Plugin<[{ router?: Router; tracker: Tracker }]> = {
  install(app, options) {
    const router: Router | undefined = options.router
    const tracker: Tracker = options.tracker
    app.provide(TRACKER_KEY, tracker)
    if (router) {
      router.afterEach(to => {
        tracker.track('$pageview', {
          path: to.path
        })
      })
    }
  }
}

export function useTracker() {
  return inject<Tracker>(TRACKER_KEY, new ConsoleTracker())
}
