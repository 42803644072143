/* tslint:disable */
/* eslint-disable */
/**
 * POS API
 * Internal POS API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCustomer200ResponsePoints
 */
export interface GetCustomer200ResponsePoints {
    /**
     * 
     * @type {number}
     * @memberof GetCustomer200ResponsePoints
     */
    actualPoints: number;
    /**
     * 
     * @type {number}
     * @memberof GetCustomer200ResponsePoints
     */
    earnedPoints: number;
}

/**
 * Check if a given object implements the GetCustomer200ResponsePoints interface.
 */
export function instanceOfGetCustomer200ResponsePoints(value: object): value is GetCustomer200ResponsePoints {
    if (!('actualPoints' in value) || value['actualPoints'] === undefined) return false;
    if (!('earnedPoints' in value) || value['earnedPoints'] === undefined) return false;
    return true;
}

export function GetCustomer200ResponsePointsFromJSON(json: any): GetCustomer200ResponsePoints {
    return GetCustomer200ResponsePointsFromJSONTyped(json, false);
}

export function GetCustomer200ResponsePointsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCustomer200ResponsePoints {
    if (json == null) {
        return json;
    }
    return {
        
        'actualPoints': json['actualPoints'],
        'earnedPoints': json['earnedPoints'],
    };
}

export function GetCustomer200ResponsePointsToJSON(json: any): GetCustomer200ResponsePoints {
    return GetCustomer200ResponsePointsToJSONTyped(json, false);
}

export function GetCustomer200ResponsePointsToJSONTyped(value?: GetCustomer200ResponsePoints | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'actualPoints': value['actualPoints'],
        'earnedPoints': value['earnedPoints'],
    };
}

