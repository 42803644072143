/* tslint:disable */
/* eslint-disable */
/**
 * POS API
 * Internal POS API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetCustomer200ResponseCustomerAddressesInner } from './GetCustomer200ResponseCustomerAddressesInner';
import {
    GetCustomer200ResponseCustomerAddressesInnerFromJSON,
    GetCustomer200ResponseCustomerAddressesInnerFromJSONTyped,
    GetCustomer200ResponseCustomerAddressesInnerToJSON,
    GetCustomer200ResponseCustomerAddressesInnerToJSONTyped,
} from './GetCustomer200ResponseCustomerAddressesInner';

/**
 * 
 * @export
 * @interface GetCustomer200ResponseCustomer
 */
export interface GetCustomer200ResponseCustomer {
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseCustomer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseCustomer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseCustomer
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseCustomer
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseCustomer
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseCustomer
     */
    source: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseCustomer
     */
    phoneNumberCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseCustomer
     */
    creationTime: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseCustomer
     */
    internalNote: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetCustomer200ResponseCustomer
     */
    marketingCommunication: boolean;
    /**
     * 
     * @type {Array<GetCustomer200ResponseCustomerAddressesInner>}
     * @memberof GetCustomer200ResponseCustomer
     */
    addresses: Array<GetCustomer200ResponseCustomerAddressesInner>;
}

/**
 * Check if a given object implements the GetCustomer200ResponseCustomer interface.
 */
export function instanceOfGetCustomer200ResponseCustomer(value: object): value is GetCustomer200ResponseCustomer {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('surname' in value) || value['surname'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('source' in value) || value['source'] === undefined) return false;
    if (!('creationTime' in value) || value['creationTime'] === undefined) return false;
    if (!('internalNote' in value) || value['internalNote'] === undefined) return false;
    if (!('marketingCommunication' in value) || value['marketingCommunication'] === undefined) return false;
    if (!('addresses' in value) || value['addresses'] === undefined) return false;
    return true;
}

export function GetCustomer200ResponseCustomerFromJSON(json: any): GetCustomer200ResponseCustomer {
    return GetCustomer200ResponseCustomerFromJSONTyped(json, false);
}

export function GetCustomer200ResponseCustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCustomer200ResponseCustomer {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'surname': json['surname'],
        'email': json['email'],
        'phoneNumber': json['phoneNumber'],
        'source': json['source'],
        'phoneNumberCode': json['phoneNumberCode'] == null ? undefined : json['phoneNumberCode'],
        'creationTime': json['creationTime'],
        'internalNote': json['internalNote'],
        'marketingCommunication': json['marketingCommunication'],
        'addresses': ((json['addresses'] as Array<any>).map(GetCustomer200ResponseCustomerAddressesInnerFromJSON)),
    };
}

export function GetCustomer200ResponseCustomerToJSON(json: any): GetCustomer200ResponseCustomer {
    return GetCustomer200ResponseCustomerToJSONTyped(json, false);
}

export function GetCustomer200ResponseCustomerToJSONTyped(value?: GetCustomer200ResponseCustomer | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'surname': value['surname'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'source': value['source'],
        'phoneNumberCode': value['phoneNumberCode'],
        'creationTime': value['creationTime'],
        'internalNote': value['internalNote'],
        'marketingCommunication': value['marketingCommunication'],
        'addresses': ((value['addresses'] as Array<any>).map(GetCustomer200ResponseCustomerAddressesInnerToJSON)),
    };
}

