import moment from 'moment'

import i18n from '@/i18n'
import { AdyenChargeResponse } from '@/integrations/AdyenIPPPlugin.js'
import { RedsysChargeResponse } from '@/integrations/dataphone/redsysDataphone.js'
import { Payment } from '@/types/payment'

import {
  DoubleLabel,
  EmptySeparator,
  Label,
  Section,
  Separator,
  Total
} from './components.js'

const textStyle = {
  firstStyle: 'bold 20px Nunito Sans',
  secondStyle: '20px Nunito Sans',
  secondType: ''
}

const titleStyle = {
  align: 'left',
  style: 'bold 25px',
  lineHeight: 30
}

function splitDateAndHour(creationTime: string | Date) {
  return {
    day: moment(creationTime).format('DD/MM/YYYY'),
    hour: moment(creationTime).format('HH:mm')
  }
}

function getStoreAndCardInfo(payment: BankReceipt) {
  return [
    new Section(i18n.global.t('bank-receipt.information')),
    new DoubleLabel(
      i18n.global.t('bank-receipt.commerce'),
      payment.commerce,
      textStyle
    ),
    new DoubleLabel(
      i18n.global.t('bank-receipt.terminal'),
      payment.terminal,
      textStyle
    ),
    new DoubleLabel(
      i18n.global.t('bank-receipt.client-card'),
      payment.clientCard,
      textStyle
    ),
    new EmptySeparator()
  ]
}

function getSaleInfo(payment: Payment, metadata: BankReceipt) {
  const { day, hour } = splitDateAndHour(payment.creationTime)
  return [
    new Section(i18n.global.t('bank-receipt.sale')),
    new DoubleLabel(
      i18n.global.t('bank-receipt.autorization'),
      metadata.resCode,
      textStyle
    ),
    new DoubleLabel(
      i18n.global.t('bank-receipt.order'),
      metadata.orderNumber,
      textStyle
    ),
    new DoubleLabel(i18n.global.t('bank-receipt.date'), day, textStyle),
    new DoubleLabel(i18n.global.t('bank-receipt.hour'), hour, textStyle),
    new EmptySeparator()
  ]
}

function getTransactionInfo(payment: BankReceipt) {
  return [
    new Section(payment.tagApp),
    new DoubleLabel(
      i18n.global.t('bank-receipt.application'),
      payment.idApp,
      textStyle
    ),
    new DoubleLabel(
      i18n.global.t('bank-receipt.n-trans'),
      payment.transactionNum,
      textStyle
    ),
    new DoubleLabel(
      i18n.global.t('bank-receipt.tvr'),
      payment.resVerification,
      textStyle
    ),
    new DoubleLabel(
      i18n.global.t('bank-receipt.response'),
      payment.resCodeAuto,
      textStyle
    ),
    new EmptySeparator()
  ]
}

function getPaymentInfo(payment: Payment) {
  return [new Total(payment.amount)]
}

type BankReceipt = {
  commerce: string
  terminal: string
  clientCard: string
  resCode: string
  orderNumber: string
  tagApp: string
  idApp: string
  transactionNum: string
  resCodeAuto: string
  resVerification: string
}

function mapPayment(
  metadata: RedsysChargeResponse | AdyenChargeResponse
): BankReceipt {
  if ('receipt' in metadata) {
    return {
      commerce: metadata.receipt.commerce,
      terminal: metadata.receipt.terminal,
      clientCard: metadata.receipt.clientCard,
      resCode: metadata.receipt.resCode,
      orderNumber: metadata.receipt.orderNumber,
      tagApp: metadata.receipt.tagApp,
      idApp: metadata.receipt.idApp,
      transactionNum: metadata.receipt.transactionNum,
      resCodeAuto: metadata.receipt.resCodeAuto,
      resVerification: metadata.receipt.resVerification
    }
  } else {
    const rawReceipt =
      metadata.SaleToPOIResponse.PaymentResponse.PaymentReceipt[1].OutputContent
        .OutputText
    const receipt: Record<string, string> = {}
    rawReceipt.forEach(el => {
      const searchParams = new URLSearchParams(el.Text)
      const key = searchParams.get('key')
      if (key) {
        receipt[key] = searchParams.get('value') ?? ''
      }
    })
    return {
      commerce: receipt.txRef,
      terminal: receipt.ptid,
      clientCard: receipt.pan,
      resCode: receipt.authCode,
      orderNumber: receipt.txRef,
      tagApp: receipt.paymentMethodVariant,
      idApp: receipt.aid,
      transactionNum: receipt.mref,
      resVerification: receipt.cvmRes,
      resCodeAuto: receipt.authCode
    }
  }
}

function generateBankReceipt(payment: Payment) {
  const bankReceipt = mapPayment(payment.metadata as any)
  return [
    new EmptySeparator(),
    new Label(i18n.global.t('bank-receipt.title'), titleStyle),
    ...getStoreAndCardInfo(bankReceipt),
    ...getSaleInfo(payment, bankReceipt),
    ...getTransactionInfo(bankReceipt),
    new Separator(),
    ...getPaymentInfo(payment)
  ]
}

export default generateBankReceipt
