import { useEventListener } from '@vueuse/core'
import { computed, unref, type MaybeRef } from 'vue'

const DEFAULT_DELAY = 100
const DEFAULT_INTERVAL = 100

export interface OnHoldOptions {
  /**
   * Time in ms till `hold` gets called
   *
   * @default 100
   */
  delay?: number

  /**
   * Time in ms of the interval between each `hold` call
   *
   * @default 100
   */
  interval?: number
}

export function onHold(
  target: MaybeRef<Element | null>,
  handler: (evt: PointerEvent) => void,
  options?: OnHoldOptions
) {
  const elementRef = computed(() => unref(target))

  let timeout: ReturnType<typeof setTimeout> | undefined
  let interval: ReturnType<typeof setInterval> | undefined

  function clear() {
    if (timeout) {
      clearTimeout(timeout)
      timeout = undefined
    }
    if (interval) {
      clearInterval(interval)
      interval = undefined
    }
  }

  function onRelease() {
    clear()
  }

  function onDown(ev: PointerEvent) {
    clear()

    timeout = setTimeout(() => {
      interval = setInterval(() => {
        handler(ev)
      }, options?.interval ?? DEFAULT_INTERVAL)
    }, options?.delay ?? DEFAULT_DELAY)
  }

  const cleanup = [
    useEventListener(elementRef, 'pointerdown', onDown),
    useEventListener(window, ['pointerup', 'pointerleave'], onRelease)
  ]

  const stop = () => cleanup.forEach(fn => fn())

  return stop
}
