/* tslint:disable */
/* eslint-disable */
/**
 * POS API
 * Internal POS API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCustomer200ResponseCustomerAddressesInner
 */
export interface GetCustomer200ResponseCustomerAddressesInner {
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseCustomerAddressesInner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseCustomerAddressesInner
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseCustomerAddressesInner
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseCustomerAddressesInner
     */
    details: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseCustomerAddressesInner
     */
    postalCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetCustomer200ResponseCustomerAddressesInner
     */
    selected: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseCustomerAddressesInner
     */
    latitude: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCustomer200ResponseCustomerAddressesInner
     */
    longitude: string | null;
}

/**
 * Check if a given object implements the GetCustomer200ResponseCustomerAddressesInner interface.
 */
export function instanceOfGetCustomer200ResponseCustomerAddressesInner(value: object): value is GetCustomer200ResponseCustomerAddressesInner {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('customerId' in value) || value['customerId'] === undefined) return false;
    if (!('address' in value) || value['address'] === undefined) return false;
    if (!('details' in value) || value['details'] === undefined) return false;
    if (!('postalCode' in value) || value['postalCode'] === undefined) return false;
    if (!('selected' in value) || value['selected'] === undefined) return false;
    if (!('latitude' in value) || value['latitude'] === undefined) return false;
    if (!('longitude' in value) || value['longitude'] === undefined) return false;
    return true;
}

export function GetCustomer200ResponseCustomerAddressesInnerFromJSON(json: any): GetCustomer200ResponseCustomerAddressesInner {
    return GetCustomer200ResponseCustomerAddressesInnerFromJSONTyped(json, false);
}

export function GetCustomer200ResponseCustomerAddressesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCustomer200ResponseCustomerAddressesInner {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': json['customerId'],
        'address': json['address'],
        'details': json['details'],
        'postalCode': json['postalCode'],
        'selected': json['selected'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
    };
}

export function GetCustomer200ResponseCustomerAddressesInnerToJSON(json: any): GetCustomer200ResponseCustomerAddressesInner {
    return GetCustomer200ResponseCustomerAddressesInnerToJSONTyped(json, false);
}

export function GetCustomer200ResponseCustomerAddressesInnerToJSONTyped(value?: GetCustomer200ResponseCustomerAddressesInner | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'customerId': value['customerId'],
        'address': value['address'],
        'details': value['details'],
        'postalCode': value['postalCode'],
        'selected': value['selected'],
        'latitude': value['latitude'],
        'longitude': value['longitude'],
    };
}

