import { reactive, ref } from 'vue'
import type { App as Application } from 'vue'

import { mapNotification } from './mapper'
import type { Notification, NotificationConstructor } from './types'

export const notifications: Notification[] = reactive([])
export const fixedNotifications: Notification[] = reactive([])
export const fixedComponentOpen = ref<boolean>(false)

const addNotification = (
  newNotification: NotificationConstructor
): Notification => {
  const notification = mapNotification(newNotification)

  if (newNotification.type !== 'info') {
    fixedNotifications.unshift(notification)
  }

  if (!fixedComponentOpen.value) {
    notifications.unshift(notification)
  }
  return notification
}

const openFixedComponent = () => {
  notifications.length = 0
  fixedComponentOpen.value = true
}

const closeFixedComponent = () => {
  fixedComponentOpen.value = false
}

const updateProgress = (id: string, progress: number) => {
  const notificationIndex = notifications.findIndex(
    notification => notification.id === id
  )
  const notification = notifications[notificationIndex]
  if (notification && notification.progress) {
    notification.progress.value = progress
  }
}

export const notification = {
  create: addNotification,
  openFixedComponent,
  closeFixedComponent,
  updateProgress,
  fixedNotifications
}

const notificationPlugin = {
  install(app: Application) {
    app.config.globalProperties.$lnotification = notification
    app.provide('$lnotification', notification)
  }
}

export default notificationPlugin
