import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia'
import { v4 as uuid } from 'uuid'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import { useDialog } from '@last/core-ui/paprika'

import ticket from '@/assets/ticket.svg'
import type { CalculatorPayload } from '@/components/Calculator/CalculatorUtils'
import { useCheckout } from '@/composables/useCheckout'
import { useKitchenOrders } from '@/composables/useKitchenOrders'
import { useMoney } from '@/composables/useMoney'
import { useTabs } from '@/composables/useTabs'
import i18n from '@/i18n'
import { useConfigStore } from '@/store/config'
import { useTabsStore } from '@/store/tabs'
import sync from '@/sync/service'
import { Tab } from '@/types'

export const useFastMode = defineStore('fastMode', () => {
  const router = useRouter()

  const configStore = useConfigStore()
  const { config } = storeToRefs(configStore)

  const tabsStore = useTabsStore()
  const { tabs } = storeToRefs(tabsStore)

  const fastTabId = ref<string | null>(null)
  const pickupType = ref<boolean>(false)

  function createVirtualTab(id: string): Tab {
    let newTab = tabsStore.formatTab({
      tab: {
        id: id,
        name: 'Fast mode'
      }
    })
    fastTabId.value = newTab.id

    newTab = {
      ...newTab,
      shared: [],
      seats: [],
      bills: [],
      kitchenOrders: []
    }

    return newTab
  }

  function resetTab() {
    // refresh FastPOS
    fastTabId.value = null
    load()
  }

  function load(): void {
    router.replace({
      name: 'fastMode',
      params: {
        tabId: fastTabId.value ?? uuid()
      }
    })
  }

  function addTabToSystem(): void {
    if (!fastTabId.value) return

    const currentFastTab = tabs.value[fastTabId.value]
    if (currentFastTab) return

    const tab = tabsStore.formatTab({
      tab: {
        id: fastTabId.value,
        name: 'Fast mode',
        pickupType: pickupType.value ? 'takeAway' : undefined
      }
    })
    sync.record('tabOpened', tab)
  }

  function saveTab(): void {
    if (!fastTabId.value) return

    addTabToSystem()

    // refresh FastPOS
    fastTabId.value = null
    load()
  }

  async function doFastPayment(method: string) {
    if (!fastTabId.value) return

    // Create kitchenOrders for tab
    if (config.value.enableKitchenOrders) {
      const { sendKitchenOrder } = useKitchenOrders(fastTabId.value)
      await sendKitchenOrder('all')
    }

    // Process payment (card|cash)
    const calculatorValues = ref<CalculatorPayload>({
      toPay: 0,
      pending: 0,
      change: 0,
      tip: 0
    })
    const { charge, currentBill, selectPaymentMethod } = useCheckout(
      fastTabId.value,
      'fastCheckout',
      calculatorValues
    )
    selectPaymentMethod(method)

    const total = currentBill.value.total
    calculatorValues.value.toPay = total

    await charge()

    const { tab } = useTabs(fastTabId.value)

    if (!tab.value.open) {
      const dialog = useDialog()
      const { currency } = useMoney()
      dialog({
        image: ticket,
        title: i18n.global.t('checkout.fast-mode.printing-ticket'),
        content: i18n.global.t('checkout.fast-mode.total-ticket', {
          amount: currency(total)
        })
      })

      resetTab()
    }
  }

  async function doCheckout(): Promise<void> {
    if (!fastTabId.value) return

    // Create kitchenOrders for tab
    if (config.value.enableKitchenOrders) {
      const { sendKitchenOrder } = useKitchenOrders(fastTabId.value)
      await sendKitchenOrder('all')
    }
    router.push({
      name: 'checkout',
      params: {
        tabId: fastTabId.value
      },
      query: { referrer: 'fastMode' }
    })
  }

  function closeTab() {
    if (!fastTabId.value) return
    tabsStore.closeTab({ tabId: fastTabId.value, closedWithPin: false })
    fastTabId.value = null
  }

  return {
    fastTabId,
    pickupType,
    load,
    closeTab,
    doFastPayment,
    doCheckout,
    addTabToSystem,
    resetTab,
    createVirtualTab,
    saveTab
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFastMode, import.meta.hot))
}
