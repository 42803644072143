<template>
  <div class="flex justify-between items-center mb-4">
    <div class="font-body text-sm text-n-200">
      {{ $t('till-report.start-date') }}
    </div>
    <div class="font-heading font-medium">
      {{ $d(tillReport.start, 'date') }}
    </div>
  </div>
  <div class="flex justify-between items-center mb-4">
    <div class="font-body text-sm text-n-200">
      {{ $t('till-report.end-date') }}
    </div>
    <div class="font-heading font-medium">
      {{ $d(tillReport.end, 'date') }}
    </div>
  </div>

  <table class="w-full">
    <tbody>
      <tr class="border-b border-n-200 font-body text-sm text-n-200 text-left">
        <th class="px-2 py-3"></th>
        <th class="px-2 py-3">{{ $t('report.theoretical') }}</th>
        <th class="px-2 py-3">{{ $t('report.real') }}</th>
        <th class="px-2 py-3">{{ $t('report.difference') }}</th>
      </tr>
      <tr v-for="amount in tillReport.amounts" :key="amount.method">
        <td class="px-2 py-3 capitalize">
          {{ $t('bill.' + amount.method, amount.method) }}
        </td>
        <td class="px-2 py-3">{{ currency(amount.expectedEnd) }}</td>
        <td class="px-2 py-3">
          <template v-if="amount.end !== undefined">
            {{ currency(amount.end) }}
          </template>
          <template v-else>-</template>
        </td>
        <td class="px-2 py-3">
          <template v-if="amount.end !== undefined">
            {{ currency(amount.end - amount.expectedEnd) }}
          </template>
          <template v-else>-</template>
        </td>
      </tr>
      <tr class="border-t border-n-200">
        <td class="px-2 py-3">{{ $t('report.total') }}</td>
        <td class="px-2 py-3">
          {{ currency(tillReport.expectedEndAmount) }}
        </td>
        <td class="px-2 py-3">{{ currency(tillReport.endAmount) }}</td>
        <td class="px-2 py-3">
          {{ currency(tillReport.endAmount - tillReport.expectedEndAmount) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import { useMoney } from '@/composables/useMoney'
import { TillReport } from '@/types'

defineProps<{
  tillReport: TillReport
  name: string
}>()

const { currency } = useMoney()
</script>
