/* tslint:disable */
/* eslint-disable */
/**
 * POS API
 * Internal POS API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCouriers200ResponseInner
 */
export interface GetCouriers200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetCouriers200ResponseInner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetCouriers200ResponseInner
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetCouriers200ResponseInner
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof GetCouriers200ResponseInner
     */
    phoneNumber?: string;
}

/**
 * Check if a given object implements the GetCouriers200ResponseInner interface.
 */
export function instanceOfGetCouriers200ResponseInner(value: object): value is GetCouriers200ResponseInner {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('code' in value) || value['code'] === undefined) return false;
    return true;
}

export function GetCouriers200ResponseInnerFromJSON(json: any): GetCouriers200ResponseInner {
    return GetCouriers200ResponseInnerFromJSONTyped(json, false);
}

export function GetCouriers200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCouriers200ResponseInner {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'code': json['code'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
    };
}

export function GetCouriers200ResponseInnerToJSON(json: any): GetCouriers200ResponseInner {
    return GetCouriers200ResponseInnerToJSONTyped(json, false);
}

export function GetCouriers200ResponseInnerToJSONTyped(value?: GetCouriers200ResponseInner | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'code': value['code'],
        'phoneNumber': value['phoneNumber'],
    };
}

