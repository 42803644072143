<template>
  <div
    class="bg-n-0 dark:bg-n-800 border border-n-100 dark:border-n-600 flex rounded-lg p-2 flex-col shadow py-4 px-6"
  >
    <l-input v-model="query" icon="search" size="small" icon-position="left" />
    <div
      class="flex flex-col overflow-y-scroll max-h-56 whitespace-no-wrap mt-4"
    >
      <div
        v-for="c in filteredCountries"
        :key="c.code"
        class="py-2 border-b border-n-50 dark:border-n-700 last:border-b-0 hover:bg-n-50 transition rounded-md dark:hover:bg-n-700 cursor-pointer"
        @click="() => selectCountry(c)"
      >
        <div class="ml-2 flex">
          <l-icon
            :name="`flags/${c.code.toLowerCase() as Lowercase<CountryCode>}`"
            original-color
          />
          <span class="text-n-500 dark:text-n-200 ml-2"
            >{{ c.name }}
            <span class="text-n-200 dark:text-n-500">(+{{ c.prefix }})</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getCountryCallingCode, type CountryCode } from 'libphonenumber-js'
import { computed, defineEmits, defineProps, ref } from 'vue'

import supportedCountries from '@last/core/src/supportedCountries.js'

import LIcon from '../../LIcon.vue'
import LInput from '../LInput.vue'
import { type CountryOption } from './types'

type Props = {
  preferredCountries?: CountryCode[]
  countryName?: string
}

const props = withDefaults(defineProps<Props>(), {
  preferredCountries: () => ['ES', 'US', 'GB', 'FR', 'DE', 'IT', 'PT'],
  countryName: ''
})

const emit = defineEmits<{
  select: [CountryOption]
}>()

const query = ref(props.countryName)

const regionNames = new Intl.DisplayNames(['es'], { type: 'region' })
const countries = computed(
  () =>
    props.preferredCountries
      .map(country => {
        return {
          name: regionNames.of(country),
          code: country,
          prefix: getCountryCallingCode(country)
        }
      })
      .concat(
        supportedCountries.sort().flatMap((country: CountryCode) => {
          if (props.preferredCountries.includes(country)) return []
          try {
            const prefix = getCountryCallingCode(country)
            return [
              {
                name: regionNames.of(country),
                code: country,
                prefix
              }
            ]
          } catch (_) {
            return []
          }
        })
      )
      .filter(option => !!option.name) as CountryOption[]
)

const filteredCountries = computed(() => {
  if (!query.value) return countries.value
  const q = query.value.toLowerCase()
  return countries.value.filter(
    c =>
      c.name?.toLowerCase().includes(q) ||
      c.code.toLowerCase().includes(q) ||
      c.prefix.toLowerCase().includes(q)
  )
})

function selectCountry(country: CountryOption) {
  emit('select', country)
}
</script>
