<template>
  <teleport to="#appRoot">
    <div class="fixed inset-0 bg-n-800">
      <div class="flex flex-col h-full w-full">
        <top-bar
          class="flex-none"
          show-back
          :show-close="false"
          @back="$emit('close')"
        >
          <template #center>
            <div class="flex items-center gap-2 font-heading font-bold text-xl">
              <span v-if="tab.code">
                <span class="font-bold">{{ tab.code }}</span>
                <span v-if="tab.tableName"> - {{ tab.tableName }}</span>
                <span v-else-if="tab.name"> - {{ tab.name }}</span>
              </span>
              <span v-else-if="tab.tableName">{{ tab.tableName }}</span>
              <span v-else>{{ tab.name }}</span>
            </div>
          </template>
        </top-bar>
        <div class="flex-1 overflow-y-auto scrolling-touch">
          <div class="p-4">
            <bills-list
              :bills="bills"
              :selected-bill-id="selectedBillId"
              :current-bill-id="currentBillId"
              @bill-selected="$emit('billSelected', $event)"
              @invalidate="$emit('invalidate', $event)"
              @generate-invoice="$emit('generateInvoice', $event)"
              @cancel-invoice="$emit('cancelInvoice', $event)"
            />
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import BillsList from '@/components/checkout/BillsList.vue'
import { useTabs } from '@/composables/useTabs'
import TopBar from '@/mobile/components/TopBar.vue'
import { BillWithPayments } from '@/types'

type Props = {
  tabId: string
  bills: BillWithPayments[]
  selectedBillId?: string
  currentBillId?: string
}

const props = withDefaults(defineProps<Props>(), {
  bills: () => [],
  selectedBillId: '',
  currentBillId: ''
})

defineEmits<{
  close: []
  billSelected: [string]
  invalidate: [string]
  generateInvoice: [string]
  cancelInvoice: [string]
  sendInvoice: [string]
}>()

const { tab } = useTabs(props.tabId)
</script>
