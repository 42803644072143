<template>
  <l-modal
    :title="$t('reservations.new')"
    :open="open"
    :button-text="$t('reservations.accept')"
    :button-enabled="isComplete"
    @action="save()"
    @close="$emit('close')"
  >
    <div class="flex items-center justify-between gap-4">
      <l-field :label="$t('reservations.name')" class="w-full">
        <l-input
          v-model="booking.name"
          :placeholder="$t('reservations.name')"
          icon="user"
        />
      </l-field>
      <l-field :label="$t('reservations.surname')" class="w-full">
        <l-input
          v-model="booking.surname"
          :placeholder="$t('reservations.surname')"
        />
      </l-field>
    </div>

    <l-field :label="$t('reservations.phone-number')">
      <l-phone-input
        v-model="booking.phoneNumber"
        icon="phone"
        :placeholder="$t('reservations.phone-number')"
        :wrong-value="validating && !validPhoneNumber"
        :default-country-code="config.locationCountryCode"
      />
    </l-field>

    <div class="flex items-center justify-between gap-4">
      <l-field class="w-full" :label="$t('reservations.date')">
        <l-date-picker
          v-model="booking.dateTime"
          :placeholder="$t('reservations.time-placeholder')"
          :only-future="true"
          :show-time="false"
          :default-time="defaultTime"
        />
      </l-field>
      <l-field class="w-full" :label="$t('reservations.time')">
        <l-time-picker
          v-model="booking.dateTime"
          :minute-step="15"
          picker-size="large"
          :placeholder="$t('reservations.time-placeholder')"
          :default="defaultTime"
        />
      </l-field>
    </div>

    <div class="flex items-center justify-between gap-4">
      <l-field :label="$t('reservations.diners')" class="w-full">
        <l-input
          v-model="booking.diners"
          :placeholder="$t('reservations.diners-placeholder')"
          type="number"
        />
      </l-field>
      <l-field :label="$t('reservations.table')" class="w-full">
        <l-input
          :model-value="
            booking?.tables?.length ? printTables(booking.tables) : null
          "
          icon="external"
          icon-position="right"
          :placeholder="$t('reservations.no-table')"
          read-only
          @click="$emit('selectTable')"
        />
      </l-field>
    </div>

    <l-field :label="$t('reservations.comments')">
      <l-input
        v-model="booking.customerComments"
        type="textarea"
        :placeholder="$t('reservations.comments-placeholder')"
      />
    </l-field>
  </l-modal>
</template>

<script setup lang="ts">
import { format, roundToNearestMinutes } from 'date-fns'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { storeToRefs } from 'pinia'
import { v4 as uuid } from 'uuid'
import { computed, defineModel, ref } from 'vue'

import {
  LDatePicker,
  LField,
  LInput,
  LModal,
  LPhoneInput,
  LTimePicker
} from '@last/core-ui/paprika'

import { useBookingsStore } from '@/store/bookings'
import { useConfigStore } from '@/store/config'
import { useTablesStore } from '@/store/tables'
import { Booking } from '@/types'

defineProps<{
  open: boolean
}>()

const emit = defineEmits<{
  close: []
  selectTable: []
}>()

const booking = defineModel<Partial<Booking>>({
  default: {}
})

const tablesStore = useTablesStore()
const configStore = useConfigStore()
const { createBooking, editBooking } = useBookingsStore()

const { tables } = storeToRefs(tablesStore)
const { config } = storeToRefs(configStore)

const validating = ref(false)

const isComplete = computed(() => {
  return (
    !!booking.value?.name &&
    !!booking.value?.diners &&
    !!booking.value?.dateTime
  )
})

const defaultTime = computed(() => {
  return format(roundToNearestMinutes(new Date(), { nearestTo: 15 }), 'HH:mm')
})

const validPhoneNumber = computed(() => {
  const parsed = parsePhoneNumberFromString(booking.value?.phoneNumber || '')
  return parsed?.isValid()
})

function formatNumber(phoneNumber: string) {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber)
  return parsedNumber ? parsedNumber.number : phoneNumber
}

function save() {
  validating.value = true
  if (!validPhoneNumber.value) return
  let formattedReservation = {
    ...booking.value,
    phoneNumber: formatNumber(booking.value.phoneNumber!)
  }
  if (formattedReservation.id) {
    editBooking(formattedReservation as Booking)
  } else {
    formattedReservation = {
      ...formattedReservation,
      id: uuid(),
      cancelled: false
    }
    createBooking(formattedReservation as Booking)
  }
  emit('close')
}

function printTables(bookedTables: string[]) {
  return bookedTables.map(table => tables.value[table].name).toString()
}
</script>
